const state = {
  courseWizardGroup: {},
  courseWizardPrintoutTemplates: [],
  courseWizardTags: [],
  courseWizardVenues: [],
  courseWizardEvaluationTemplates: [],
  courseWizardGroupEvaluations: [],
  courseWizardRegistrationTemplates: [],
  courseWizardGroupRegistrations: [],
  courseWizardAdministrators: [],
};

const getters = {
  courseWizardGroup: (state) => state.courseWizardGroup,
  courseWizardPrintoutTemplates: (state) => state.courseWizardPrintoutTemplates,
  courseWizardTags: (state) => state.courseWizardTags,
  courseWizardVenues: (state) => state.courseWizardVenues,
  courseWizardEvaluationTemplates: (state) => state.courseWizardEvaluationTemplates,
  courseWizardGroupEvaluations: (state) => state.courseWizardGroupEvaluations,
  courseWizardRegistrationTemplates: (state) => state.courseWizardRegistrationTemplates,
  courseWizardGroupRegistrations: (state) => state.courseWizardGroupRegistrations,
  courseWizardAdministrators: (state) => state.courseWizardAdministrators,
};

const mutations = {
  SET_COURSE_WIZARD_GROUP: (state, data) => {
    state.courseWizardGroup = data;
  },
  SET_COURSE_WIZARD_PRINTOUT_TEMPLATES: (state, data) => {
    state.courseWizardPrintoutTemplates = data;
  },
  SET_COURSE_WIZARD_TAGS: (state, data) => {
    state.courseWizardTags = data;
  },
  SET_COURSE_WIZARD_VENUES: (state, data) => {
    state.courseWizardVenues = data;
  },
  SET_COURSE_WIZARD_EVALUATION_TEMPLATES: (state, data) => {
    state.courseWizardEvaluationTemplates = data;
  },
  SET_COURSE_WIZARD_GROUP_EVALUATIONS: (state, data) => {
    state.courseWizardGroupEvaluations = data;
  },
  SET_COURSE_WIZARD_REGISTRATION_TEMPLATES: (state, data) => {
    state.courseWizardRegistrationTemplates = data;
  },
  SET_COURSE_WIZARD_GROUP_REGISTRATIONS: (state, data) => {
    state.courseWizardGroupRegistrations = data;
  },
  SET_COURSE_WIZARD_ADMINISTRATORS: (state, params) => {
    state.courseWizardAdministrators = params;
  },
};

const actions = {
  setCourseWizardGroup: async ({ commit, dispatch }, data) => {
    commit("SET_COURSE_WIZARD_GROUP", data)
  },
  setCourseWizardPrintoutTemplates: async ({ commit, dispatch }, data) => {
    commit("SET_COURSE_WIZARD_PRINTOUT_TEMPLATES", data)
  },
  setCourseWizardTags: async ({ commit, dispatch }, data) => {
    commit("SET_COURSE_WIZARD_TAGS", data)
  },
  setCourseWizardVenues: async ({ commit, dispatch }, data) => {
    commit("SET_COURSE_WIZARD_VENUES", data)
  },
  setCourseWizardEvaluationTemplates: async ({ commit, dispatch }, data) => {
    commit("SET_COURSE_WIZARD_EVALUATION_TEMPLATES", data)
  },
  setCourseWizardGroupEvaluations: async ({ commit, dispatch }, data) => {
    commit("SET_COURSE_WIZARD_GROUP_EVALUATIONS", data)
  },
  setCourseWizardRegistrationTemplates: async ({ commit, dispatch }, data) => {
    commit("SET_COURSE_WIZARD_REGISTRATION_TEMPLATES", data)
  },
  setCourseWizardGroupRegistrations: async ({ commit, dispatch }, data) => {
    commit("SET_COURSE_WIZARD_GROUP_REGISTRATIONS", data)
  },
  setCourseWizardAdministrators: async ({ commit }, params) => {
    commit("SET_COURSE_WIZARD_ADMINISTRATORS", params)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
