import api from "@/api/api";

const state = {
  leftMenu: {},
  showLeftMenu: true,
  isNavigationDrawerVisible: false,
};

const getters = {
  getLeftMenu: (state) => state.leftMenu,
  getShowLeftMenu: (state) => state.showLeftMenu,
  isNavigationDrawerVisible: (state) => state.isNavigationDrawerVisible,
};

const mutations = {
  SET_LEFT_MENU: (state, list) => {
    state.leftMenu = list
      .filter((menu) => menu.slug === "group_menu" && menu.enabled)
      .shift();
  },
  setShowLeftMenu: (state, status) => (state.showLeftMenu = status),
  SET_NAVIGATION_DRAWER_VISIBILITY: (state, val) =>
    (state.isNavigationDrawerVisible = val),
};

const actions = {
  getGroupLeftMenu: async ({ commit, dispatch }, params) => {
    const response = await api.groupMenus.list(...params);

    if (response) {
      commit("SET_LEFT_MENU", response.data.data);
      return response;
    }
  },
  setShowLeftMenu: async ({ state, commit }, status) => {
    commit("setShowLeftMenu", status);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
