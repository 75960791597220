import variables from "@/assets/styles/variables.scss";

const icons = {
  dragHandle: `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="grip-vertical" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="draggable-handle theme-accent svg-inline--fa fa-grip-vertical fa-w-10"><path fill="${variables.accent}" d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"></path></svg>`,
  copy: `<svg data-v-61aee622="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-clone fa-w-16 theme-accent" style="font-size: 12px;"><path data-v-61aee622="" fill="${variables.accent}" d="M464 0c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48H176c-26.51 0-48-21.49-48-48V48c0-26.51 21.49-48 48-48h288M176 416c-44.112 0-80-35.888-80-80V128H48c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48v-48H176z" class=""></path></svg>`,
  edit: `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="theme-accent svg-inline--fa fa-pen fa-w-16"><path fill="${variables.accent}" d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z" class=""></path></svg>`,
  plus: `<svg data-v-0c0c9438="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="icon svg-inline--fa fa-plus fa-w-14"><path data-v-0c0c9438="" fill="${variables.accent}" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" class=""></path></svg>`,
  import: `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-upload" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="theme-accent svg-inline--fa fa-file-upload fa-w-12"><path fill="${variables.accent}" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>`,
  addGuest: `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-tie" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="theme-accent svg-inline--fa fa-user-tie fa-w-14"><path fill="${variables.accent}" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm95.8 32.6L272 480l-32-136 32-56h-96l32 56-32 136-47.8-191.4C56.9 292 0 350.3 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-72.1-56.9-130.4-128.2-133.8z" class=""></path></svg>`,
  script: `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-code fa-w-20"><path fill="currentColor" d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z" class=""></path></svg>`,
  eye: `<i data-v-dc20e15c="" aria-hidden="true" class="v-icon material-icons theme--light sw-accent" style="font-size: 16px;">visibility</i>`,
};

export default {
  QuestionWizard:
    "Here you have the opportunity to create a quiz or a poll. Once you have created your questions, you can send a notification to the app, link to this quiz/poll, and then your guests can answer it and possibly. win prizes or whatever you need the answers to. Be creative.",
  AudienceListPage:
    "Audiences are used to send out messages (SMS's, emails or push messages) in Treffio. Audiences can be created by you and can be used to sent messages to registered guests based on their answers.<br><br>Examples of how to use the audience could be to send a message to everyone that has answered they are vegetarians, coming with a certain transport or the people staying overnight at the hotel.<br><br>Later in Treffio, we will make it possible to make custom audiences, so audiences don't need to be based on data, but can also simply be a special selection you are making manually. Treffio also has on our roadmap to make audience-based content so you have even more flexibility on your events.",
  PagesPlaceInMenu: `Use the ${icons.dragHandle} to move the menu point to your desired location.`,
  SignupName:
    "This is an internal description only. This will not be displayed to guests, and will only be for your identification of the form",
  RegistrationQuestionOptions:
    "Use quantity if you have limited availability for this option. Do you only have room for 20 people at the hotel, you can set this to 20. These options disappear in the form when the max quantity has been filled. Keep it empty to have infinite quantity on an option",
  RegistrationQuestionOptionsMultiple:
    "You can add many options at once by inserting multi-line text. Each line of text will produce an option. Empty lines are automatically removed. To fill Quantity and Price, separate them with semicolon (;), for example T-shirt;20;49.99.",
  SignupAddQuestion:
    "Be aware that you don't need to ask for first name, last name, email and phone. This is by default always asked as first steps in a registration",
  SignupEmailConfirmation:
    "This email will be sent out to all guests who have completed the registration. Both existing and new guests will receive it.",
  GroupImage:
    "Choose your thumbnail picture to easily recognize your event among all your events! Only for internal use.",
  GroupAddAdministrator:
    "Add administrators to help you setup your event. You can have multiple people working to setup the event at the same time",
  GroupList: `Here you can create more events. Each event will have its own environment where you can invite your own administrators to each individual environment as you please. Each environment can have its own registration form, landing page, and mobile app. <br><br>You can duplicate an existing event. Just press on ${icons.copy} in the top right corner of an event. Thereby an entire event is copied, without participants, so you can make new events based on your own special formula.`,
  AppListPage: `Pages are automatically added to your landing page and mobile app. Download the Treffio mobile app to see your event here. To select where in the menu you want the pages, click the ${icons.edit}, and edit the menu step`,
  ArticleListPage:
    "The news module can be used to regularly have new content at your event for your guests. Opposed to pages, the news will be represented on a list and have a badge if they are not read",
  UsersPage: `There are three ways of getting guests into your app. Either you can import them all with the import feature you can find when hovering on the ${icons.plus} or and then clicking on ${icons.import}.<br><br>You can also add guests manually by hovering on the ${icons.plus} and choosing the ${icons.addGuest} icon.<br><br>The perhaps most common way is though to make a registration form and send and putting it on your landing page`,
  EmailSenderAddress:
    "Emails from the system will automatically be sent from no-reply@treffio.com.  You have the opportunity to change this sender-email by having it approved - please contact the Treffio-team at info@treffio.com. You have to be the owner of the email you want approved party@yourcompany.com. Once this '@yourcompany.com' sender has been approved, you do not need to get it approved again at other events created in Treffio - e.g. conference@yourcompany.com, summerparty@yourcompany.com etc.",
  LandingPageSettingsImages:
    "The header image is placed at the very top of the website.<br></br>Footer background is placed at the very bottom of the website.<br></br>The logo is placed at the very bottom of the page on top of the footer background.",
  QuestionList: `Here you have the opportunity to create a quiz or a poll. Once you have created your questions, you can send a notification to the app, link to this quiz / poll and then your guests can answer it and possibly. win prizes or whatever you need the answers to. Be creative!`,
  QuestionLimit:
      `You can add a maximum of :{limit} questions for each :{type}.`,
  InactiveMessage: "If the date is set and inactive the message will not be sent",
  GroupMessageTemplateWizardMessageSubjectInfoText: "Guests will receive the message in their preferred language",
  GroupMessageTemplateWizardMessageTitleInfoText: "Guests will receive the message in their preferred language",
  GroupMessageTemplateWizardMessageBodyInfoText: "Guests will receive the message in their preferred language",
  SignupWizardEmailSubjectInfoText: "Guests will receive the message in their preferred language",
  SignupWizardEmailBodyInfoText: "Guests will receive the message in their preferred language",
  SignupWizardQuestionsOptionsPrice: "Please contact our support to enable paid options",
  SignupWizardQuestionsOptionsText: "Select \"Customized options\" as the display type to enable the text field",
  ShortcodeUserAnswer: "If a guest has answered I\'m coming to the event, then this text will be in the email",
  AppPageListTitleTooltip: "When creating a page, the page will by default show in both the landing page and app. You can change this when editing each page.",
};
