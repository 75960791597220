const state = {
  appLanguage: "",
  appContentLanguage: "",
};
const getters = {
  appLanguage: (state) => state.appLanguage,
  appContentLanguage: (state) => state.appContentLanguage,
};
const mutations = {
  SET_APP_LANGUAGE: (state, lang) => (state.appLanguage = lang),
  SET_APP_CONTENT_LANGUAGE: (state, lang) => (state.appContentLanguage = lang),
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
