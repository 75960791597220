import axios from "axios";

export const users = {
  create: (specs) => axios.post(`/users`, specs),
  list: (params) => axios.get(`/users`, { params }),
  get: (id, params) => axios.get(`/users/${id}`, { params }),
  search: (params) => axios.get(`/users/search`, { params }),
  update: (id, specs) => axios.post(`/users/${id}`, specs),
  delete: (id) => axios.delete(`/users/${id}`),
  mass_delete: (params) => axios.delete(`/users`, { params }),
};
