import axios from "axios";
import qs from "qs";

export const groupMenus = {
  create: (groupId, specs) => axios.post(`/groups/${groupId}/menus`, qs.stringify(specs)),
  list: (groupId, params) => axios.get(`/groups/${groupId}/menus`, { params }),
  get: (groupId, id, params) => axios.get(`/groups/${groupId}/menus/${id}`, { params }),
  update: (groupId, id, specs) => axios.post(`/groups/${groupId}/menus/${id}`, specs),
  delete: (groupId, id) => axios.delete(`/groups/${groupId}/menus/${id}`),
};
