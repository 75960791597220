const state = {
  articleWizardArticle: {},
};

const getters = {
  articleWizardArticle: (state) => state.articleWizardArticle,
};

const mutations = {
  SET_ARTICLE_WIZARD_ARTICLE: (state, data) => {
    state.articleWizardArticle = data;
  },
};

const actions = {
  setArticleWizardArticle: async ({ commit, dispatch }, data) => {
    commit("SET_ARTICLE_WIZARD_ARTICLE", data)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
