var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showProgress),expression:"!showProgress"}],attrs:{"xs12":"","px-3":"","pt-3":"","pb-2":""}},[_c('vue-dropzone',{ref:"mediaUploader",attrs:{"id":"dropzone-custom","options":_vm.dropzoneOptions,"useCustomSlot":true},on:{"vdropzone-sending":_vm.vdropzoneSending,"vdropzone-queue-complete":_vm.vdropzoneQueueComplete,"vdropzone-processing":_vm.vdropzoneProcessing,"vdropzone-upload-progress":_vm.vdropzoneUploadProgress,"vdropzone-error":_vm.vdropzoneError,"vdropzone-thumbnail":_vm.thumbnail}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title theme-accent"},[_vm._v(" "+_vm._s(_vm.$t("media_manager.drag_and_drop_to_upload"))+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("media_manager.click_to_select_a_file_from_your_computer"))+" ")])])])],1),(_vm.showProgress)?_c('v-flex',{attrs:{"xs12":"","px-3":"","pt-3":"","pb-2":""}},[_c('v-progress-linear',{staticClass:"theme-accent",model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","px-2":""}},[(_vm.imageToCrop)?_c('v-layout',{staticClass:"cropper-layout",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs2":"","px-2":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"hide-details":""},model:{value:(_vm.imageToCrop.name),callback:function ($$v) {_vm.$set(_vm.imageToCrop, "name", $$v)},expression:"imageToCrop.name"}})],1),_c('v-flex',{class:'file-size ' +
                (_vm.maxFileSizeForUploadInvalid(
                  _vm.imageToCrop['file'] || _vm.imageToCrop.size,
                )
                  ? 'error--text'
                  : ''),attrs:{"xs12":""}},[_vm._v(_vm._s(_vm.$t("size"))+": "+_vm._s(_vm.bytesToSize(_vm.imageToCrop.size))+" ")])],1)],1),_c('v-flex',{attrs:{"xs10":"","px-2":""}},[_c('cropper',{ref:"cropper",staticClass:"cropper",style:({
              opacity: _vm.maxFileSizeForUploadInvalid(
                _vm.imageToCrop['file'] || _vm.imageToCrop.size,
              )
                ? 0.3
                : 1,
            }),attrs:{"src":_vm.imageToCrop.dataURL,"stencil-props":{ aspectRatio: _vm.aspectRatio }},on:{"change":_vm.updateCrop}}),_c('div',{staticClass:"delete"},[_c('v-btn',{staticClass:"ma-0",attrs:{"icon":""},on:{"click":_vm.saveCrop}},[_c('font-awesome-icon',{staticClass:"sw-accent",attrs:{"icon":"check"}})],1),_c('v-btn',{staticClass:"ma-0",attrs:{"icon":""},on:{"click":function($event){_vm.imageToCrop = null}}},[_c('font-awesome-icon',{staticClass:"sw-accent",attrs:{"icon":"times"}})],1)],1)],1)],1):_vm._e(),(!_vm.imageToCrop)?_c('v-layout',{staticClass:"image-layout",attrs:{"row":"","wrap":""}},[(_vm.uploadedFiles && _vm.uploadedFiles.length > 0)?_c('overlay-scrollbars',{style:({ width: '100%' }),attrs:{"options":_vm.scrollbarOptions}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.uploadedFiles),function(file,index){return _c('v-flex',{key:'file-' + index,staticClass:"file justify-center",attrs:{"xs12":"","sm6":"","md4":"","lg3":"","xl2":""}},[_c('v-card',{staticClass:"card-container",attrs:{"outlined":""}},[_c('v-flex',{staticClass:"text-center img-item"},[(file.dataURL)?_c('img',{staticClass:"img",style:({
                      opacity: _vm.maxFileSizeForUploadInvalid(file) ? 0.3 : 1,
                    }),attrs:{"src":file.dataURL}}):_vm._e()]),_c('v-flex',{staticClass:"file-name"},[_c('v-text-field',{attrs:{"hide-details":"","color":"while","loading":""},model:{value:(file.name),callback:function ($$v) {_vm.$set(file, "name", $$v)},expression:"file.name"}})],1),_c('v-flex',{class:'file-size ' +
                    (_vm.maxFileSizeForUploadInvalid(file) ? 'error--text' : '')},[_c('span',[_vm._v(_vm._s(_vm.$t("size"))+": "+_vm._s(_vm.bytesToSize(file.size)))])]),_c('div',{staticClass:"delete"},[_c('v-btn',{staticClass:"ma-0",attrs:{"icon":""},on:{"click":function($event){_vm.imageToCrop = file}}},[_c('font-awesome-icon',{staticClass:"sw-accent",attrs:{"icon":"crop"}})],1),_c('v-btn',{staticClass:"ma-0",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteMedia(index)}}},[_c('font-awesome-icon',{staticClass:"sw-accent",attrs:{"icon":"trash"}})],1)],1)],1)],1)}),1)],1):_vm._e()],1):_vm._e()],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-divider')],1),_c('v-flex',{staticClass:"xs12 px-2 py-3 text-xs-right"},[(_vm.hasInvalidFile)?_c('p',{staticClass:"d-inline-block pr-3 pl-3 error--text"},[_vm._v(" "+_vm._s(_vm.$t("media_manager.every_image_should_not_more_than", { maxFileSizeForUpload: _vm.maxFileSizeForUpload, }))+" ")]):_vm._e(),_c('v-btn',{class:'theme-primary-bg theme-on-primary text-none',attrs:{"round":"","large":"","disabled":_vm.isUploadButtonDisable,"loading":_vm.isLoading},on:{"click":_vm.uploadMedia}},[_vm._v(" "+_vm._s(_vm.$t("media_manager.upload_media_btn_text"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }