import axios from "axios";

export const groupMenuDestinations = {
  create: (groupId, menuId, specs) => axios.post(`/groups/${groupId}/menus/${menuId}/destinations`, specs),
  list: (groupId, menuId, params) => axios.get(`/groups/${groupId}/menus/${menuId}/destinations`, { params }),
  get: (groupId, menuId, id, params) => axios.get(`/groups/${groupId}/menus/${menuId}/destinations/${id}`, { params }),
  update: (groupId, menuId, id, specs) => axios.post(`/groups/${groupId}/menus/${menuId}/destinations/${id}`, specs),
  delete: (groupId, menuId, id) => axios.delete(`/groups/${groupId}/menus/${menuId}/destinations/${id}`),
  reorder: (groupId, menuId, specs) => axios.patch(`/groups/${groupId}/menus/${menuId}/destinations/reorder`, specs),
};
