import axios from "axios";
import qs from "qs";

export const groupUserAttributeRelations = {
  get: (groupId, id, params) =>
    axios.get(`/groups/${groupId}/users/attributes/${id}/relations`, {
      params,
    }),
  store: (groupId, id, specs) =>
    axios.post(
      `/groups/${groupId}/users/attributes/${id}/relations`,
      qs.stringify(specs, { strictNullHandling: true, encode: false }),
    ),
  delete: (groupId, id) =>
    axios.delete(`/groups/${groupId}/users/attributes/${id}/relations`),
};
