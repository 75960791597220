import axios from "axios";

export const groupAdministrators = {
  list: (groupId, params) => axios.get(`/groups/${groupId}/administrators`, { params }),
  create: (groupId, specs) => axios.post(`/groups/${groupId}/administrators`, specs),
  get: (groupId, id) => axios.get(`/groups/${groupId}/administrators/${id}`),
  update: (groupId, id, specs) => axios.post(`/groups/${groupId}/administrators/${id}`, specs),
  delete: (groupId, id) => axios.delete(`/groups/${groupId}/administrators/${id}`),
  roles: (groupId, params) => axios.get(`/groups/${groupId}/administrators/roles`, { params }),
};
