import axios from "axios";

export const groupUserAttributes = {
  create: (groupId, specs) =>
    axios.post(`/groups/${groupId}/users/attributes`, specs),
  list: (groupId, params) =>
    axios.get(`/groups/${groupId}/users/attributes`, { params }),
  related: (groupId, params) =>
    axios.post(`/groups/${groupId}/users/attributes/list_related`, params),
  get: (groupId, id, params) =>
    axios.get(`/groups/${groupId}/users/attributes/${id}`, { params }),
  update: (groupId, id, specs) =>
    axios.post(`/groups/${groupId}/users/attributes/${id}`, specs),
  sort: (groupId, specs) =>
    axios.post(`/groups/${groupId}/users/attributes/sort`, specs),
  duplicate: (groupId, params) =>
    axios.get(`/groups/${groupId}/users/attributes/duplicate`, { params }),
};
