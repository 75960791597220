<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="border-radius">
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-btn icon @click="dialog = false">
          <v-icon small>close</v-icon></v-btn
        ></v-card-actions
      >
      <v-card-text class="text-xs-center pt-0">
        <div class="mb-2">
          <div class="mb-3 headline font-weight-medium">
            {{ title }}
          </div>
          <div class="mb-3">
            <v-text-field
              v-model="text"
              :label="$t('shortcode.link_label')"
              @keyup.enter.native="confirm"
            ></v-text-field>
          </div>
        </div>
        <v-btn
          round
          class="theme-primary-bg theme-on-primary text-none"
          @click="cancel"
          >{{ $t("common.cancel") }}</v-btn
        >
        <v-btn
          round
          class="theme-primary-bg theme-on-primary text-none"
          @click="confirm"
          >{{ $t("insert") }}</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    title: "",
    text: "",
  }),
  methods: {
    async open(title, text) {
      this.title = title;
      this.text = text;
      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    cancel() {
      this.dialog = false;
      this.resolve();
    },
    confirm() {
      this.dialog = false;
      this.resolve(this.text);
    },
  },
};
</script>
