import { auth } from "@/api/auth";
import { authAddresses } from "@/api/authAddresses";
import { authStripe } from "@/api/authStripe";
import { media } from "@/api/media";
import { plans } from "@/api/plans";
import { plugins } from "@/api/plugins";
import { roles } from "@/api/roles";
import { users } from "@/api/users";
import { userAttributes } from "@/api/userAttributes";
import { messageTemplates } from "@/api/messageTemplates";
import { printoutTemplates } from "@/api/printoutTemplates";
import { groups } from "@/api/groups";
import { groupGeneralAttributes } from "@/api/groupGeneralAttributes";
import { groupGeneralAttributeCategories } from "@/api/groupGeneralAttributeCategories";
import { groupPlugins } from "@/api/groupPlugins";
import { groupPluginAttributes } from "@/api/groupPluginAttributes";
import { groupMenus } from "@/api/groupMenus";
import { groupAudiences } from "@/api/groupAudiences";
import { groupMessageTemplates } from "@/api/groupMessageTemplates";
import { groupMenuDestinations } from "@/api/groupMenuDestinations";
import { groupArticles } from "@/api/groupArticles";
import { groupTicketTemplates } from "@/api/groupTicketTemplates";
import { groupTickets } from "@/api/groupTickets";
import { groupTriggers } from "@/api/groupTriggers";
import { groupArticleGeneralAttributes } from "@/api/groupArticleGeneralAttributes";
import { groupAdministrators } from "@/api/groupAdministrators";
import { groupUsers } from "@/api/groupUsers";
import { groupRoles } from "@/api/groupRoles";
import { groupPolicies } from "@/api/groupPolicies";
import { groupTags } from "@/api/groupTags";
import { groupVenues } from "@/api/groupVenues";
import { groupReportParams } from "@/api/groupReportParams";
import { groupReports } from "@/api/groupReports";
import { groupAppUsers } from "@/api/groupAppUsers";
import { groupUserMessages } from "@/api/groupUserMessages";
import { groupUserPhysicalTokens } from "@/api/groupUserPhysicalTokens";
import { groupUserAttributeRelations } from "@/api/groupUserAttributeRelations";
import { groupUserAttributes } from "@/api/groupUserAttributes";
import { groupUserAttributeCategories } from "@/api/groupUserAttributeCategories";
import { groupUserAttributeTags } from "@/api/groupUserAttributeTags";
import { groupTransactions } from "@/api/groupTransactions";
import { settings } from "@/api/settings";
import { expenses } from "@/api/expenses";

export default {
  auth,
  authAddresses,
  authStripe,
  media,
  plans,
  plugins,
  roles,
  users,
  userAttributes,
  messageTemplates,
  printoutTemplates,
  groups,
  groupGeneralAttributes,
  groupGeneralAttributeCategories,
  groupPlugins,
  groupPluginAttributes,
  groupMenus,
  groupAudiences,
  groupMessageTemplates,
  groupMenuDestinations,
  groupArticles,
  groupTicketTemplates,
  groupTickets,
  groupTriggers,
  groupArticleGeneralAttributes,
  groupAdministrators,
  groupUsers,
  groupRoles,
  groupVenues,
  groupPolicies,
  groupTags,
  groupReportParams,
  groupReports,
  groupAppUsers,
  groupUserMessages,
  groupUserPhysicalTokens,
  groupUserAttributeRelations,
  groupUserAttributes,
  groupUserAttributeCategories,
  groupUserAttributeTags,
  groupTransactions,
  settings,
  expenses,
};
