import axios from "axios";

export const groupGeneralAttributeCategories = {
  list: (params) => axios.get(`/groups/attributes/categories`, { params }),
  // get: params =>
  //   axios.get(
  //     `/settings/${params.id}`,
  //     params
  //   ),
  create: (params) => axios.post(`/groups/attributes/categories`, params),
  // update: params =>
  //   axios.post(
  //     `/settings/${params.id}`,
  //     params.model
  //   ),
  // delete: params =>
  //   axios.delete(`/settings/${params.id}`)
};
