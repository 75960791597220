import axios from "axios";

export const groupMessageTemplates = {
  create: (groupId, specs) =>
    axios.post(`/groups/${groupId}/message_templates`, specs),
  list: (groupId, params) =>
    axios.get(`/groups/${groupId}/message_templates`, { params }),
  get: (groupId, id, params) =>
    axios.get(`/groups/${groupId}/message_templates/${id}`, { params }),
  update: (groupId, id, specs) =>
    axios.post(`/groups/${groupId}/message_templates/${id}`, specs),
  delete: (groupId, id) =>
    axios.delete(`/groups/${groupId}/message_templates/${id}`),
  send_test: (groupId, id, params) =>
    axios.post(`/groups/${groupId}/message_templates/${id}/send_test`, params),
  update_email_statuses: (groupId, id) =>
    axios.post(`/groups/${groupId}/message_templates/${id}/update_email_statuses`),
};
