<template>
  <v-snackbar
    v-model="notifications"
    :timeout="timeout"
    :left="true"
    :bottom="true"
    auto-height
    color="red"
  >
    <v-layout row wrap class="notification-content">
      <v-flex xs12 align-center>
        <v-toolbar flat color="red" class="notification-toolbar">
          <v-toolbar-title class="white--text subheading font-weight-medium">
            <font-awesome-icon icon="exclamation-circle" /><span class="pl-2"
              >Error</span
            ></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn class="ma-0" icon flat @click="closeAjaxErrors">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-flex>
      <v-flex
        xs12
        v-for="(notification, index) in errorNotifications"
        :key="`notification-${index}`"
      >
        <span>{{ notification.message }}</span>
      </v-flex>
    </v-layout>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ErrorNotifications",
  data() {
    return {
      notifications: false,
      timeout: null,
    };
  },
  computed: {
    ...mapGetters(["getErrorNotifications"]),
    errorNotifications() {
      return this.getErrorNotifications;
    },
  },
  watch: {
    errorNotifications(val) {
      if (val && val.length > 0) {
        this.notifications = true;
      } else {
        this.notifications = false;
      }
    },
  },
  methods: {
    closeAjaxErrors() {
      this.notifications = false;
      this.$store.dispatch("deleteErrorNotifications");
    },
  },
};
</script>

<style scoped>
.notification-content {
  max-width: 300px;
}

.notification-toolbar >>> .v-toolbar__content {
  height: auto !important;
  padding: 0;
}
</style>
