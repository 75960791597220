import Vue from "vue";
import moment from "moment-timezone";

Vue.mixin({
  computed: {
    gmPrimaryColor() {
      return this.$store.getters.activeGroup &&
        this.$store.getters.activeGroup.attributes &&
        this.$store.getters.activeGroup.attributes.lp_primary_color
        ? this.$store.getters.activeGroup.attributes.lp_primary_color
        : "#000";
    },
    gmSecondaryColor() {
      return this.$store.getters.activeGroup &&
        this.$store.getters.activeGroup.attributes &&
        this.$store.getters.activeGroup.attributes.lp_secondary_color
        ? this.$store.getters.activeGroup.attributes.lp_secondary_color
        : "#000";
    },
    cssVariables() {
      return {
        "--primary-color": this.gmPrimaryColor,
        "--secondary-color": this.gmSecondaryColor,
      };
    },
    datePickerLang() {
      return {
        days: this.$t("datePickerDays").split(","),
        months: this.$t("datePickerMonths").split(","),
      };
    },
    userTimezone() {
      return moment.tz.guess();
    },
  },
  created() {
    moment.tz.setDefault("UTC");
  },
  methods: {
    parseContent(content) {
      if (!content) return "";

      const replaceFrom0 = /class=[\'\"]ql-align-([a-z]*)[\'\"]/g;
      const replaceTo0 = 'class="text-$1"';
      content = content.replace(replaceFrom0, replaceTo0);

      const replaceFrom1 =
        /\<shortcode-tag tag="([-a-z]+)" name="([_a-z]+)" value="([_0-9a-z]+)" instance="([_a-z]+)" text="(.*?)">(.*?)<\/shortcode-tag>/g;
      const replaceTo1 = '[$1 $2="$3" $4]$5[/$1]';

      content = content.replace(replaceFrom1, replaceTo1);

      if (this.editorConfig.toolbar) {
        return content.replace(/\n/g, "<br>");
      }

      return content.replace(/<[^>]*>/g, "");
    },
    unparseContent(content) {
      if (!content) return "";

      const replaceFrom0 = /class=[\'\"]text-([a-z]*)[\'\"]/g;
      const replaceTo0 = 'class="ql-align-$1"';

      content = content.replace(replaceFrom0, replaceTo0);

      const replaceFrom1 =
        /\[([-a-z]+) ([_a-z]+)="([_0-9a-z]+)" ([_a-z]+)](.*?)\[\/([-a-z]+)]/g;
      const replaceTo1 =
        '<shortcode-tag tag="$1" name="$2" value="$3" instance="$4" text="$5">$5</shortcode-tag>';

      content = content.replace(replaceFrom1, replaceTo1);

      return content;
    },
    nl2br(str) {
      if (!str) return;
      return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
    transformDate(date) {
      if (!date) return "";
      return moment.tz(date, this.userTimezone).format("YYYY-MM-DD HH:mm");
    },
  },
});
