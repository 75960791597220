import axios from "axios";
import qs from "qs";

export const groups = {
  create: (specs) => axios.post(`/groups`, qs.stringify(specs)),
  duplicate: (id, specs) => axios.post(`/groups/${id}/duplicate`, specs),
  list: (params) => axios.get(`/groups`, { params }),
  get: (id, params) => axios.get(`/groups/${id}`, { params }),
  balance: (id, params) => axios.get(`/groups/${id}/balance`, { params }),
  update: (id, specs) => axios.post(`/groups/${id}`, qs.stringify(specs)),
  delete: (id) => axios.delete(`/groups/${id}`),
};
