const state = {
  groupPageGroup: {},
  groupPageGroupAttributes: {},
};

const getters = {
  groupPageGroup: (state) => state.groupPageGroup,
  groupPageGroupAttributes: (state) => state.groupPageGroupAttributes,
};

const mutations = {
  SET_GROUP_PAGE_GROUP: (state, data) => {
    state.groupPageGroup = data;
  },
  SET_GROUP_PAGE_GROUP_ATTRIBUTES: (state, data) => {
    state.groupPageGroupAttributes = data;
  },
};

const actions = {
  setGroupPageGroup: async ({ commit, dispatch }, data) => {
    commit("SET_GROUP_PAGE_GROUP", data)
  },
  setGroupPageGroupAttributes: async ({ commit, dispatch }, data) => {
    commit("SET_GROUP_PAGE_GROUP_ATTRIBUTES", data)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
