export const state = {
  successNotifications: [],
  errorNotifications: [],
};

const getters = {
  getSuccessNotifications: (state) => state.successNotifications,
  getErrorNotifications: (state) => state.errorNotifications,
};

const mutations = {
  ADD_SUCCESS_NOTIFICATION: (state, notification) =>
    state.successNotifications.push(notification),
  REMOVE_SUCCESS_NOTIFICATION: (state) =>
    setTimeout(() => state.successNotifications.shift(), 4000),
  CLEAR_ALL_SUCCESS_NOTIFICATIONS: (state) => (state.successNotifications = []),
  ADD_ERROR_NOTIFICATION: (state, notification) =>
    state.errorNotifications.push(notification),
  REMOVE_ERROR_NOTIFICATION: (state) =>
    setTimeout(() => state.errorNotifications.shift(), 4000),
  CLEAR_ALL_ERROR_NOTIFICATIONS: (state) => (state.errorNotifications = []),
};

const actions = {
  addNotification: async ({ commit }, notification) => {
    commit("ADD_SUCCESS_NOTIFICATION", notification);
    commit("REMOVE_SUCCESS_NOTIFICATION");
  },
  deleteNotifications: async ({ commit }) => {
    commit("CLEAR_ALL_SUCCESS_NOTIFICATIONS");
  },
  addErrorNotification: async ({ commit }, notification) => {
    commit("ADD_ERROR_NOTIFICATION", notification);
    commit("REMOVE_ERROR_NOTIFICATION");
  },
  deleteErrorNotifications: async ({ commit }) => {
    commit("CLEAR_ALL_ERROR_NOTIFICATIONS");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
