var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{},[_c('SuccessNotifications'),_c('ErrorNotifications'),(_vm.token && _vm.token.length)?[_c('AppToolbar'),_c('AppNavigationDrawer')]:_vm._e(),_c('v-content',{class:[
      'app-main',
      _vm.currentRouteName,
      {
        authorized: _vm.isAuthorized,
        drawer: _vm.isDrawerVisible,
        mini: !_vm.drawer,
      },
    ]},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1),(_vm.updateExists)?_c('v-btn',{staticClass:"update-btn theme-primary-bg theme-on-primary text-none",attrs:{"round":""},on:{"click":_vm.activateNewServiceWorker}},[_vm._v("Update")]):_vm._e(),_c('Tawk')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }