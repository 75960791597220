import axios from "axios";

export const groupTriggers = {
  create: (groupId, specs) => axios.post(`/groups/${groupId}/triggers`, specs),
  list: (groupId, params) => axios.get(`/groups/${groupId}/triggers`, { params }),
  get: (groupId, id, params) => axios.get(`/groups/${groupId}/triggers/${id}`, { params }),
  update: (groupId, id, specs) => axios.post(`/groups/${groupId}/triggers/${id}`, specs),
  delete: (groupId, id) => axios.delete(`/groups/${groupId}/triggers/${id}`),
  test: (groupId, id, params) => axios.post(`/groups/${groupId}/triggers/${id}/test`, { params }),
};
