import Vue from "vue"
import {
  wrappedDepositsListRequest,
  wrappedDepositsCreateRequest,
  wrappedDepositsUpdateRequest,
  wrappedDepositsDeleteRequest,
  wrappedDepositsHistoryRequest,
  wrappedCheckedinCountRequest,
} from "../requests/deposits"

const state = {
  deposits: [],
}
const getters = {
  getDeposits: (state) => state.deposits,
}
const mutations = {
  ADD_DEPOSIT: (state, deposit) => {
    deposit.new = true
    state.deposits.push(deposit)
  },
  SET_DEPOSITS: (state, deposits) => (state.deposits = deposits),
  REMOVE_DEPOSIT: (state, deposit_id) => {
    const index = state.deposits.findIndex((deposit) => deposit.id == deposit_id)
    if (index > -1) {
      Vue.delete(state.deposits, index)
    }
  },
}
const actions = {
  createDeposits: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositsCreateRequest(params)
    if (data) {
      commit('ADD_DEPOSIT', data.data)
      return data
    }
  },
  listDeposits: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositsListRequest(params)
    if (data) {
      commit('SET_DEPOSITS', data.data)
      return data
    }
  },
  updateDeposits: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositsUpdateRequest(params)
    if (data) {
      commit('REMOVE_DEPOSIT', data.data.id)
      return data
    }
  },
  deleteDeposits: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositsDeleteRequest(params)
    if (data) {
      return data
    }
  },
  depositsHistory: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositsHistoryRequest(params)
    if (data) {
      return data
    }
  },
  getCheckedinCount: async ({ commit, dispatch }, params) => {
    const data = await wrappedCheckedinCountRequest(params)
    if (data) {
      return data
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
