const state = {
  questionWizardPlugin: {},
  questionWizardCategory: [],
  questionWizardAttributes: [],
  questionWizardMessageTemplate: {},
  questionWizardIonicAppSidemenu: {},
};

const getters = {
  questionWizardPlugin: (state) => state.questionWizardPlugin,
  questionWizardCategory: (state) => state.questionWizardCategory,
  questionWizardAttributes: (state) => state.questionWizardAttributes,
  questionWizardIonicAppSidemenu: (state) => state.questionWizardIonicAppSidemenu,
  questionWizardMessageTemplate: (state) => state.questionWizardMessageTemplate,
};

const mutations = {
  SET_QUESTION_WIZARD_PLUGIN: (state, data) => {
    state.questionWizardPlugin = data;
  },
  SET_QUESTION_WIZARD_CATEGORY: (state, data) => {
    state.questionWizardCategory = data;
  },
  SET_QUESTION_WIZARD_ATTRIBUTES: (state, data) => {
    state.questionWizardAttributes = data;
  },
  SET_QUESTION_WIZARD_IONIC_APP_SIDEMENU: (state, data) => {
    state.questionWizardIonicAppSidemenu = data;
  },
  SET_QUESTION_WIZARD_MESSAGE_TEMPLATE: (state, data) => {
    state.questionWizardMessageTemplate = data;
  },
};

const actions = {
  setQuestionWizardPlugin: async ({ commit, dispatch }, data) => {
    commit("SET_QUESTION_WIZARD_PLUGIN", data)
  },
  setQuestionWizardCategory: async ({ commit, dispatch }, data) => {
    commit("SET_QUESTION_WIZARD_CATEGORY", data)
  },
  setQuestionWizardAttributes: async ({ commit, dispatch }, data) => {
    commit("SET_QUESTION_WIZARD_ATTRIBUTES", data)
  },
  setQuestionWizardIonicAppSidemenu: async ({ commit, dispatch }, data) => {
    commit("SET_QUESTION_WIZARD_IONIC_APP_SIDEMENU", data)
  },
  setQuestionWizardMessageTemplate: async ({ commit, dispatch }, data) => {
    commit("SET_QUESTION_WIZARD_MESSAGE_TEMPLATE", data)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
