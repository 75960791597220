export default {
  data: () => ({
    // SW update variables
    registration: null,
    updateExists: false,
  }),
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener("swUpdateFound", this.updateFound, {
      once: true,
    });
  },
  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateFound(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    // Called when the user accepts the update
    activateNewServiceWorker() {
      this.updateExists = false;

      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;

      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ action: "skipWaiting" });
    },
  },
};
