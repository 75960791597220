const state = {
  globalDateFormat: "ddd, MMM D, YYYY",
};

const getters = {
  globalDateFormat: (state) => state.globalDateFormat,
};

const mutations = {
  SET_GLOBAL_DATE_FORMAT: (state, data) => {
    state.globalDateFormat = data;
  },
};

const actions = {
  setGlobalDateFormat: async ({ commit }, data) => {
    commit("SET_GLOBAL_DATE_FORMAT", data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
