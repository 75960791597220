<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-btn icon @click="closeDialog()">
          <v-icon small>close</v-icon></v-btn
        ></v-card-actions
      >
      <v-card-text class="pt-0 pb-5">
        <v-layout row wrap>
          <v-flex xs12 class="text-center sw-h5">
            {{ $t("media_manager.preview_title") }}
          </v-flex>
          <v-flex xs12 class="mb-5">
            <v-select
              v-model="device"
              :items="deviceList"
              item-text="name"
              item-value="id"
              return-object
              :label="$t('media_manager.preview_device_label')"
              hide-details
            >
              <template slot="item" slot-scope="data">
                <div class="slot-item">
                  {{ data.item.name }}
                </div>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs12>
            <div class="frame">
              <div
                v-if="device && src"
                class="device"
                :style="deviceStyles"
              ></div>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { devices } from "@/components/Media/DevicePreview/devices.json";

export default {
  props: {
    backgroundSize: {
      type: String,
      default: "contain",
    },
  },
  data: () => ({
    dialog: false,
    device: null,
    src: "",
  }),
  computed: {
    deviceList() {
      return devices || [];
    },
    ratio() {
      return ((this.device || {}).ratio || "")
        .split(":")
        .map((el) => Number(el));
    },
    deviceWidth() {
      return ((100 / this.ratio[0]) * this.ratio[1]).toFixed(2);
    },
    deviceStyles() {
      return {
        width: `${this.deviceWidth}%`,
        backgroundImage: `url(${this.src})`,
        backgroundSize: this.backgroundSize,
      };
    },
  },
  methods: {
    openDialog(imgSrc) {
      this.src = imgSrc;
      this.device = this.deviceList[0];
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.src = "";
      this.device = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.frame {
  width: 100%;
  padding: 0 0 100% 0;
  position: relative;
  overflow: hidden;
  display: flex;

  .device {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    background-position: 50%;
    background-repeat: no-repeat;
    border: 2px solid $accent;
  }
}
</style>
