import axios from "axios";

export const settings = {
  create: (specs) => axios.post(`/settings`, specs),
  list: (params) => axios.get(`/settings`, { params }),
  get: (key) => axios.get(`/settings/${key}`),
  public: (key) => axios.get(`/settings/public/${key}`),
  update: (id, specs) => axios.post(`/settings/${id}`, specs),
  delete: (id) => axios.delete(`/settings/${id}`),
};
