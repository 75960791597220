<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card class="rounded">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text class="pt-0">
        <div class="mb-3 text-center headline font-weight-medium">
          {{ $t("quill.embed_modal_title") }}
        </div>
        <v-form data-vv-scope="embed" class="mb-4">
          <v-textarea
            v-model="html"
            solo
            rows="10"
            :placeholder="$t('quill.embed_modal_html_placeholder')"
            no-resize
            hide-details
          ></v-textarea>
          <ErrorMessages :error-messages="errors.collect('embed.html')" />
        </v-form>
        <div class="mb-4">
          <div>{{ $t("quill.embed_modal_resolution_label") }}</div>
          <v-radio-group
            v-model="resolutionValue"
            row
            class="ma-0"
            hide-details
          >
            <template v-for="(resolution, i) in resolutions">
              <v-radio :value="i" :key="`resolution-${i}`">
                <template slot="label">
                  <div class="black--text">{{ resolution.name }}</div>
                </template>
              </v-radio>
            </template>
          </v-radio-group>
        </div>
        <div class="text-center">
          <v-btn
            round
            class="white sw-primary text-none"
            @click="closeDialog"
            >{{ $t("common.cancel") }}</v-btn
          >
          <v-btn
            round
            class="sw-accent-bg sw-on-accent text-none"
            @click="insertEmbed"
            :disabled="!iframeSrc"
            >{{ $t("quillConfirmButtonText") }}</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    html: "",
    resolutionValue: 1,
  }),
  computed: {
    iframeSrc() {
      const regex = /<iframe[^>]+src=["']([^"']+)["'][^>]*>/i;
      const match = regex.exec(this.html) || [];

      return match[1] || "";
    },
    blot() {
      if (this.regexTest("https://www.google.", this.iframeSrc)) {
        return "embed-map";
      }

      if (
        this.regexTest("https://www.youtube.", this.iframeSrc) ||
        this.regexTest("https://player.vimeo.", this.iframeSrc)
      ) {
        return "embed-video";
      }

      return "";
    },
    resolutions() {
      return [
        {
          name: this.$t("quill.embed_modal_resolution_21x9_label"),
          paddingBottom: "42.86%",
        },
        {
          name: this.$t("quill.embed_modal_resolution_16x9_label"),
          paddingBottom: "56.25%",
        },
        {
          name: this.$t("quill.embed_modal_resolution_4x3_label"),
          paddingBottom: "75%",
        },
        {
          name: this.$t("quill.embed_modal_resolution_1x1_label"),
          paddingBottom: "100%",
        },
      ];
    },
    selectedResolution() {
      return this.resolutions[this.resolutionValue];
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.html = "";
      this.resolutionValue = 1;
    },
    regexTest(reg, string) {
      const regex = new RegExp(reg);

      return regex.test(string);
    },
    insertEmbed() {
      if (this.iframeSrc === null) {
        return;
      }

      if (!this.blot) {
        this.errors.add({
          msg: this.$t("quill.embed_modal_unsupported_error_msg"),
          scope: "embed",
          field: "html",
        });
        return;
      }

      let src = this.iframeSrc;

      if (this.blot === "embed-video") {
        src = src.replace(/\?.*$/, "");
      }

      const options = {
        src: src,
        type: this.blot,
        paddingBottom: this.selectedResolution.paddingBottom,
      };

      this.$emit("insert-embed", options);

      this.closeDialog();
    },
  },
};
</script>
