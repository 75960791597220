import axios from "axios"
import {
  wrapRequest,
} from "../helpers"

const DepositoriesCreateRequest = (params) =>
  axios.post(`/groups/${params.group_id}/depositories`, params.model)
const DepositoriesListRequest = (params) =>
  axios.get(`/groups/${params.group_id}/depositories`, params)
const DepositoriesShowRequest = (params) =>
  axios.get(`/groups/${params.group_id}/depositories/${params.depository_id}`, params)
const DepositoriesUpdateRequest = (params) =>
  axios.post(`/groups/${params.group_id}/depositories/${params.depository_id}`, params.model)
const DepositoriesDeleteRequest = (params) =>
  axios.delete(`/groups/${params.group_id}/depositories/${params.depository_id}`, params)

export const wrappedDepositoriesCreateRequest = wrapRequest(DepositoriesCreateRequest)
export const wrappedDepositoriesListRequest = wrapRequest(DepositoriesListRequest)
export const wrappedDepositoriesShowRequest= wrapRequest(DepositoriesShowRequest)
export const wrappedDepositoriesUpdateRequest = wrapRequest(DepositoriesUpdateRequest)
export const wrappedDepositoriesDeleteRequest = wrapRequest(DepositoriesDeleteRequest)
