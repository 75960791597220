<template>
  <div class="app-speed-dial">
    <transition name="slide-fade">
      <div v-if="value" class="items-slot">
        <slot name="items"></slot>
      </div>
    </transition>
    <v-btn
      icon
      class="sw-accent-bg activator"
      :class="{ open: value }"
      @click="onClick"
    >
      <template v-if="!activeIcon">
        <font-awesome-icon :icon="icon" class="default-icon" />
      </template>
      <template v-if="activeIcon">
        <font-awesome-icon :icon="icon" class="icon" />
        <font-awesome-icon :icon="activeIcon" class="active-icon" />
      </template>
    </v-btn>
    <div v-if="hint" class="pt-2 sw-caption hint">{{ hint }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: undefined,
    },
    icon: {
      type: String,
      default: "plus",
    },
    activeIcon: {
      type: String,
    },
    hint: {
      type: String,
    },
  },
  methods: {
    onClick() {
      if (typeof this.value === "boolean") {
        this.$emit("input", !this.value);
      } else {
        this.$emit("click");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes zoomin {
  from {
    transform: scale(0.06);
  }
  to {
    transform: scale(1);
  }
}

.app-speed-dial {
  position: fixed;
  right: 48px;
  bottom: 70px;
  animation: ease-in 0.3s zoomin;
  z-index: 10;

  .slide-fade-enter-active {
    transition: all 0.2s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .items-slot {
    position: absolute;
    bottom: 72px;
    right: calc(50% - 18px);
  }

  .activator {
    width: 72px;
    height: 72px;
    margin: 0;
    border: 1px solid $accent;

    .default-icon {
      transition: all 0.3s;
      color: $on-accent;
    }

    .default-icon,
    .icon,
    .active-icon {
      font-size: 18px;
      font-weight: 700;
    }

    .icon {
      display: inline-block;
      color: $on-accent;
    }

    .active-icon {
      display: none;
    }

    &.open {
      background: #ffffff !important;

      .default-icon {
        transform: rotateY(0deg) rotate(45deg); /* needs Y at 0 deg to behave properly*/
        color: $accent;
      }

      .icon {
        display: none;
      }

      .active-icon {
        display: inline-block;
        color: $accent;
      }
    }
  }

  .hint {
    position: absolute;
    right: -44px;
    width: 160px;
    text-align: center;
  }
}
</style>
