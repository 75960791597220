import router from "./router";

export const wrapRequest = (fn) => (...params) =>
  fn(...params).then(
    (response) => {
      return response.data;
    },
    (error) => {
      if (error.response.status == 404) {
        router.push({ name: "404" }).catch(() => {});
      }
      return error.response.data;
    },
  );
