<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="border-radius">
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-btn icon @click="dialog = false">
          <v-icon small>close</v-icon></v-btn
        ></v-card-actions
      >
      <v-card-text class="text-center pt-0">
        <div class="mb-5 sw-h6">{{ title }}</div>
        <v-btn round class="white sw-primary text-none" @click="cancel">{{
          cancelButton
        }}</v-btn>
        <v-btn
          round
          class="sw-secondary-bg sw-on-secondary text-none"
          @click="confirm"
          >{{ confirmButton }}</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    params: [],
    dialog: false,
    resolve: null,
  }),
  computed: {
    title() {
      return this.params[0];
    },
    cancelButton() {
      return this.params[1] ? this.params[1] : this.$t("common.no");
    },
    confirmButton() {
      return this.params[2] ? this.params[2] : this.$t("common.yes");
    },
  },
  methods: {
    async open(...params) {
      this.params = params;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
      });
    },
    cancel() {
      this.dialog = false;
      this.resolve(false);
    },
    confirm() {
      this.dialog = false;
      this.resolve(true);
    },
  },
};
</script>
