<template>
  <div class="error-messages">
    <div v-show="firstErrorMessage" class="sw-accent shake">
      {{ firstErrorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorMessages: {
      type: Array,
    },
  },
  computed: {
    firstErrorMessage() {
      return this.errorMessages ? this.errorMessages[0] : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.error-messages {
  height: 24px;
  padding: 4px 0 0 0;
  font-size: 12px;
}

.shake {
  animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
