<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" persistent width="700">
      <v-card flat>
        <v-card-text flat>
          <v-layout row wrap>
            <v-flex xs12 v-if="showTextField">
              <v-text-field
                v-model="text"
                :label="$t('quill.links_text_label')"
              ></v-text-field>
            </v-flex>
            <v-flex xs5>
              <v-radio-group v-model="window" hide-details>
                <v-radio :value="0">
                  <template slot="label">
                    <div class="black--text">
                      {{ $t("quill.links_page_option_label") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="1">
                  <template slot="label">
                    <div class="black--text">
                      {{ $t("quill.links_web_option_label") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="2">
                  <template slot="label">
                    <div class="black--text">
                      {{ $t("quill.links_registration_option_label") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="3">
                  <template slot="label">
                    <div class="black--text">
                      {{ $t("quill.links_email_option_label") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="4">
                  <template slot="label">
                    <div class="black--text">
                      {{ $t("quill.links_phone_option_label") }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs7>
              <v-layout v-show="window === 0" row wrap>
                <v-flex xs12>
                  <v-select
                    v-model="destination"
                    :items="destinations"
                    item-text="name"
                    item-value="id"
                    :label="$t('quill.links_destination_label')"
                    return-object
                    hide-details
                  >
                    <template slot="item" slot-scope="data">
                      <div class="body-2">{{ data.item.name }}</div>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12>
                  <TranslatableTooltip
                    :content="$t('quill.links_target_label_tooltip')"
                  >
                    <v-checkbox v-model="destinationBlank" hide-details>
                      <template v-slot:label>
                        <div class="black--text">
                          {{ $t("quill.links_target_label") }}
                        </div>
                      </template>
                    </v-checkbox>
                  </TranslatableTooltip>
                </v-flex>
              </v-layout>
              <v-layout v-show="window === 1" row wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="href"
                    :label="$t('quill.links_url_label')"
                    hide-details
                  ></v-text-field>
                  <ErrorMessages
                    v-show="errorMessages.href && errorMessages.href.length > 0"
                    :error-messages="errorMessages.href"
                  />
                </v-flex>
                <v-flex xs12>
                  <TranslatableTooltip
                    :content="$t('quill.links_target_label_tooltip')"
                  >
                    <v-checkbox v-model="hrefBlank" hide-details>
                      <template v-slot:label>
                        <div class="black--text">
                          {{ $t("quill.links_target_label") }}
                        </div>
                      </template>
                    </v-checkbox>
                  </TranslatableTooltip>
                </v-flex>
              </v-layout>
              <v-layout v-show="window === 2" row wrap>
                <v-flex xs12 class="mt-4">
                  <div>
                    {{ $t("quill.links_registration_option_description") }}
                  </div>
                </v-flex>
                <v-flex xs12>
                  <TranslatableTooltip
                    :content="$t('quill.links_target_label_tooltip')"
                  >
                    <v-checkbox v-model="registrationBlank" hide-details>
                      <template v-slot:label>
                        <div class="black--text">
                          {{ $t("quill.links_target_label") }}
                        </div>
                      </template>
                    </v-checkbox>
                  </TranslatableTooltip>
                </v-flex>
              </v-layout>
              <v-layout v-show="window === 3" row wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="email"
                    :label="$t('quill.links_email_label')"
                    hide-details
                  ></v-text-field>
                  <ErrorMessages
                    v-show="
                      errorMessages.email && errorMessages.email.length > 0
                    "
                    :error-messages="errorMessages.email"
                  />
                </v-flex>
              </v-layout>
              <v-layout v-show="window === 4" row wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="phone"
                    :label="$t('quill.links_phone_label')"
                    hide-details
                  ></v-text-field>
                  <ErrorMessages
                    v-show="
                      errorMessages.phone && errorMessages.phone.length > 0
                    "
                    :error-messages="errorMessages.phone"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="text-xs-center">
              <v-btn
                round
                class="white sw-primary text-none"
                @click="closeDialog()"
                >{{ $t("common.cancel") }}</v-btn
              >
              <v-btn
                round
                class="sw-accent-bg sw-on-accent text-none"
                @click="insertLink()"
                :loading="isLoading"
                :disabled="!isValid"
                >{{ $t("common.insert") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "QuillLinks",
  props: {
    selection: {
      type: Object,
      default: () => ({
        index: 0,
        length: 0,
      }),
    },
  },
  data: () => ({
    errorMessages: {
      href: [],
      email: [],
      phone: [],
    },
    window: 0,
    dialog: false,
    isLoading: false,
    text: "",
    href: "",
    email: "",
    phone: "",
    destination: null,
    destinations: [],
    destinationBlank: false,
    hrefBlank: false,
    registrationBlank: false,
    showTextField: false,
  }),
  computed: {
    group() {
      return this.$store.getters.activeGroup;
    },
    isValid() {
      if (this.showTextField && !this.text) {
        return;
      }
      if (this.window === 0) {
        return this.destination && this.destination.id;
      }
      if (this.window === 1) {
        return !!this.href;
      }
      if (this.window === 3) {
        return !!this.email;
      }
      if (this.window === 4) {
        return !!this.phone;
      }
      return true;
    },
  },
  watch: {
    dialog: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.listPages();
      },
    },
    destination: {
      handler(val) {
        if (!val || this.text || this.selection.length > 0) return;
        this.text = val.name;
      },
    },
  },
  methods: {
    async listPages() {
      try {
        const params = [this.group.id, { with_disabled: 1 }];

        this.isLoading = true;

        const response = await this.$api.groupMenus.list(...params);

        this.isLoading = false;

        const menu = response.data.data.find(
          (menu) => menu.slug === "ionic-app-sidemenu",
        );

        const supportedComponents = [
          "FrontPage",
          "QuestionPage",
          "QuestionaryReportPage",
          "TicketPage",
          "ArticlePage",
        ];

        this.destinations = menu.destinations.filter((el) =>
          supportedComponents.includes(el.component),
        );
      } catch (error) {
        this.isLoading = false;
      }
    },
    isValidUrl(href) {
      return (
        href.match(
          /^(?:(?:(?:https?|ftp):)?\/\/)?(?:(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}|(?:\d{1,3}\.){3}\d{1,3})(?::\d+)?(?:\/[^\\]*)?(?:\?[^\s]*)?(?:#[^\s]*)?$/,
        ) || href.match(/^\/#\/.*/)
      );
    },
    isValidEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    },
    isValidPhone(phone) {
      return String(phone).match(
        /^(\+\d{1,3}\s?)?(\(\d{1,4}\)|\d{1,4})[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
      );
    },
    openDialog(text, href, blank) {
      this.errorMessages = {
        href: [],
        email: [],
        phone: [],
      };
      this.text = text;
      this.href = href;
      this.email = "";
      this.phone = "";
      this.destination = null;
      this.destinations = [];
      this.destinationBlank = blank;
      this.hrefBlank = href ? blank : true;
      this.registrationBlank = blank;
      this.showTextField =
        text || (this.selection && this.selection["length"] === 0)
          ? true
          : false;
      this.window = 0;

      if (href.includes("mailto:")) {
        this.email = href.replace(/^mailto:/i, "");
        this.href = "";
        this.window = 3;
      }

      if (href.includes("/#/?section=signup")) {
        this.href = "";
        this.window = 2;
      }

      if (href.includes("tel:")) {
        this.phone = href.replace(/^tel:/i, "");
        this.href = "";
        this.window = 4;
      }

      if (this.href) {
        this.window = 1;
      }

      this.dialog = true;
    },
    async insertLink() {
      this.errorMessages = {
        href: [],
        email: [],
        phone: [],
      };

      let params = {
        text: this.text,
        href: this.href,
        blank: false,
      };

      if (this.window === 0) {
        if (!this.destination || !this.destination.id) return;

        if (this.destination.component === "FrontPage") {
          params.href = "/#/";
        }

        if (this.destination.component === "QuestionPage") {
          params.href = `/#/quiz/${this.destination.group_plugin_id}`;
        }

        if (this.destination.component === "QuestionaryReportPage") {
          params.href = `/#/answers/${this.destination.group_plugin_id}`;
        }

        if (this.destination.component === "TicketPage") {
          params.href = `/#/tickets/${this.destination.group_plugin_id}`;
        }

        if (this.destination.component === "ArticlePage") {
          const destinationParams = JSON.parse(this.destination.params);
          params.href = `/#/page/${destinationParams.article_id}`;
        }

        params.blank = this.destinationBlank;
      }

      if (this.window === 1) {
        const isValid = this.isValidUrl(this.href);

        if (!isValid) {
          this.errorMessages.href = [this.$t("quill.links_url_error_message")];
          return;
        }

        params.blank = this.hrefBlank;
      }

      if (this.window === 2) {
        params.href = "/#/?section=signup";
        params.blank = this.registrationBlank;
      }

      if (this.window === 3) {
        const isValid = this.isValidEmail(this.email);

        if (!isValid) {
          this.errorMessages.email = [
            this.$t("quill.links_email_error_message"),
          ];
          return;
        }

        params.href = `mailto:${this.email}`;
      }

      if (this.window === 4) {
        const isValid = this.isValidPhone(this.phone);

        if (!isValid) {
          this.errorMessages.phone = [
            this.$t("quill.links_phone_error_message"),
          ];
          return;
        }

        params.href = `tel:${this.phone}`;
      }

      this.$emit("insert-quill-link", params);
      this.closeDialog();
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
