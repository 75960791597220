import Vue from "vue";
import {
  wrappedDepositoriesListRequest,
  wrappedDepositoriesCreateRequest,
  wrappedDepositoriesUpdateRequest,
  wrappedDepositoriesDeleteRequest,
} from "../requests/depositories";

const state = {
  depositories: [],
};
const getters = {
  getDepositories: (state) => state.depositories,
};
const mutations = {
  ADD_DEPOSITORY: (state, depository) => {
    state.depositories.push(depository);
  },
  SET_DEPOSITORIES: (state, depositories) =>
    (state.depositories = depositories),
  UPDATE_DEPOSITORY: (state, model) => {
    const index = state.depositories.findIndex(
      (depository) => depository.id == model.id,
    );
    if (index > -1) {
      Vue.set(state.depositories, index, model);
    }
  },
  REMOVE_DEPOSITORY: (state, depository_id) => {
    const index = state.depositories.findIndex(
      (depository) => depository.id == depository_id,
    );
    if (index > -1) {
      Vue.delete(state.depositories, index);
    }
  },
};
const actions = {
  createDepositories: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositoriesCreateRequest(params);
    if (data) {
      commit("ADD_DEPOSITORY", data.data);
      return data;
    }
  },
  listDepositories: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositoriesListRequest(params);
    if (data) {
      commit("SET_DEPOSITORIES", data.data);
      return data;
    }
  },
  updateDepositories: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositoriesUpdateRequest(params);
    if (data) {
      commit("UPDATE_DEPOSITORY", data.data);
      return data;
    }
  },
  deleteDepositories: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositoriesDeleteRequest(params);
    if (data) {
      commit("REMOVE_DEPOSITORY", params.depository_id);
      return data;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
