const state = {
  groupAudienceDataStorage: [],
  groupMessageTemplateDataStorage: [],
  groupTriggerDataStorage: [],
};

const getters = {
  groupAudienceDataStorage: (state) => state.groupAudienceDataStorage,
  groupMessageTemplateDataStorage: (state) =>
    state.groupMessageTemplateDataStorage,
  groupTriggerDataStorage: (state) => state.groupTriggerDataStorage,
};

const mutations = {
  SAVE_GROUP_AUDIENCE_DATA: (state, data) => {
    state.groupAudienceDataStorage.push(data);
  },
  REMOVE_ALL_GROUP_AUDIENCES: (state, groupId) => {
    state.groupAudienceDataStorage = state.groupAudienceDataStorage.filter(
      (el) => el.group_id !== groupId,
    );
  },
  SAVE_GROUP_MESSAGE_TEMPLATE_DATA: (state, data) => {
    state.groupMessageTemplateDataStorage.push(data);
  },
  REMOVE_ALL_GROUP_MESSAGE_TEMPLATES: (state, groupId) => {
    state.groupMessageTemplateDataStorage = state.groupMessageTemplateDataStorage.filter(
      (el) => el.group_id !== groupId,
    );
  },
  SAVE_GROUP_TRIGGER_DATA: (state, data) => {
    state.groupTriggerDataStorage.push(data);
  },
  REMOVE_ALL_GROUP_TRIGGERS: (state, groupId) => {
    state.groupTriggerDataStorage = state.groupTriggerDataStorage.filter(
      (el) => el.group_id !== groupId,
    );
  },
};

const actions = {
  saveGroupAudienceDataInStorage: async ({ commit }, data) => {
    commit("SAVE_GROUP_AUDIENCE_DATA", data);
  },
  removeGroupAudiencesFromStorage: async ({ commit }, groupId) => {
    commit("REMOVE_ALL_GROUP_AUDIENCES", groupId);
  },
  saveGroupMessageTemplateDataInStorage: async ({ commit }, data) => {
    commit("SAVE_GROUP_MESSAGE_TEMPLATE_DATA", data);
  },
  removeGroupMessageTemplatesFromStorage: async ({ commit }, groupId) => {
    commit("REMOVE_ALL_GROUP_MESSAGE_TEMPLATES", groupId);
  },
  saveGroupTriggerDataInStorage: async ({ commit }, data) => {
    commit("SAVE_GROUP_TRIGGER_DATA", data);
  },
  removeGroupTriggersFromStorage: async ({ commit }, groupId) => {
    commit("REMOVE_ALL_GROUP_TRIGGERS", groupId);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
