import axios from "axios";

export const messageTemplates = {
  create: (specs) => axios.post(`/message_templates`, specs),
  list: (params) => axios.get(`/message_templates`, { params }),
  get: (id, params) => axios.get(`/message_templates/${id}`, { params }),
  update: (id, specs) => axios.post(`/message_templates/${id}`, specs),
  delete: (id) => axios.delete(`/message_templates/${id}`),
  send_test: (id) => axios.post(`/message_templates/${id}/send_test`),
};
