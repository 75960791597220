<template>
  <div style="display: inline-block">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          flat
          style="min-width: 36px"
          class="ml-0 mr-1 pa-0"
          @click="mediaDialog = true"
          v-bind="attrs"
          v-on="on"
        >
          <font-awesome-icon icon="image" />
        </v-btn>
      </template>
      <span>{{ $t("quill.toolbar_media") }}</span>
    </v-tooltip>
    <v-dialog v-model="mediaDialog" width="1200px" persistent>
      <v-card color="white lighten-4 rounded" flat>
        <v-layout row wrap v-if="mediaDialog">
          <v-flex xs12 class="pl-3 pr-1">
            <v-layout row wrap align-center>
              <v-flex xs3 title>{{ $t("media_manager.media_manager") }}</v-flex>
              <v-flex xs6>
                <v-tabs v-model="mediaTabs" show-arrows height="60">
                  <v-tab
                    v-for="(item, tabIndex) in items"
                    :key="'media-tab-' + tabIndex"
                  >
                    <span class="text-none tab-text">{{ item.name }}</span>
                  </v-tab>
                </v-tabs>
              </v-flex>
              <v-flex xs3 text-xs-right>
                <v-btn icon class="ma-0" @click="mediaDialog = false">
                  <v-icon small>close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12>
            <v-tabs-items v-model="mediaTabs" class="custom-tabs-items">
              <v-tab-item
                v-for="(item, tabItemIndex) in items"
                :key="'media-tab-item-' + tabItemIndex"
              >
                <template v-if="mediaTabs == tabItemIndex">
                  <media-upload
                    v-if="item.component == 'media-upload'"
                    :maxFilesUpload="maxFilesUpload"
                    @show-library="mediaTabs = 1"
                  ></media-upload>
                  <media-library
                    v-if="item.component == 'media-library'"
                    :maxFilesInsert="maxFilesInsert"
                    @media-insert="insertMedia"
                  ></media-library>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MediaUpload from "@/components/Media/MediaUpload.vue";
import MediaLibrary from "@/components/Media/MediaLibrary.vue";

export default {
  props: {
    value: [Array, Object],
    maxFilesUpload: {
      type: Number,
      default: 1000,
    },
    maxFilesInsert: {
      type: Number,
      default: 100,
    },
  },
  components: {
    "media-upload": MediaUpload,
    "media-library": MediaLibrary,
  },
  data: () => ({
    mediaDialog: false,
    mediaTabs: 1,
  }),
  computed: {
    items() {
      return [
        {
          name: this.$t("upload"),
          component: "media-upload",
        },
        {
          name: this.$t("media_manager.media_library"),
          component: "media-library",
        },
      ];
    },
  },
  methods: {
    insertMedia(val) {
      this.$emit("media-insert", val);
      this.mediaDialog = false;
    },
  },
};
</script>

<style>
.custom-tabs-items .v-window__container {
  height: auto !important;
}
</style>
