<template>
  <div class="tawk"></div>
</template>

<script>
export default {
  computed: {
    tawkEnabled() {
      return process.env.VUE_APP_TAWK_ENABLED === "true" ? true : false;
    },
    tawkSrc() {
      return process.env.VUE_APP_TAWK_SRC;
    },
  },
  mounted() {
    this.initTawk();
  },
  methods: {
    initTawk() {
      if (!this.tawkEnabled || !this.tawkSrc) {
        return;
      }

      var s1 = document.createElement("script");
      var s0 = document.getElementsByTagName("script")[0];

      s1.async = true;
      s1.src = this.tawkSrc;
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    },
  },
};
</script>
