<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="border-radius">
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-btn icon @click="dialog = false">
          <v-icon small>close</v-icon></v-btn
        ></v-card-actions
      >
      <v-card-text class="text-xs-center pt-0">
        <div class="mb-2">
          <div class="mb-3 headline font-weight-medium">{{ title }}</div>
          <div v-if="body && body.length > 0" class="mb-3">{{ body }}</div>
        </div>
        <v-btn
          round
          class="theme-primary-bg theme-on-primary text-none"
          @click="cancel"
          >{{ cancelButton }}</v-btn
        >
        <v-btn
          round
          class="theme-primary-bg theme-on-primary text-none"
          @click="confirm"
          >{{ confirmButton }}</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {},
  data: () => ({
    params: [],
    dialog: false,
    resolve: null,
  }),
  computed: {
    title() {
      return this.params[0];
    },
    body() {
      return this.params[1];
    },
    cancelButton() {
      return this.params[2] ? this.params[2] : "Cancel";
    },
    confirmButton() {
      return this.params[3] ? this.params[3] : "Confirm";
    },
  },
  methods: {
    async open(...params) {
      this.params = params;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
      });
    },
    cancel() {
      this.dialog = false;
      this.resolve(false);
    },
    confirm() {
      this.dialog = false;
      this.resolve(true);
    },
  },
};
</script>
