<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 px-3 pt-3 pb-2 v-show="!showProgress">
        <vue-dropzone
          ref="mediaUploader"
          id="dropzone-custom"
          @vdropzone-sending="vdropzoneSending"
          @vdropzone-queue-complete="vdropzoneQueueComplete"
          @vdropzone-processing="vdropzoneProcessing"
          @vdropzone-upload-progress="vdropzoneUploadProgress"
          @vdropzone-error="vdropzoneError"
          v-on:vdropzone-thumbnail="thumbnail"
          :options="dropzoneOptions"
          :useCustomSlot="true"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title theme-accent">
              {{ $t("media_manager.drag_and_drop_to_upload") }}
            </h3>
            <div class="subtitle">
              {{
                $t("media_manager.click_to_select_a_file_from_your_computer")
              }}
            </div>
          </div>
        </vue-dropzone>
      </v-flex>
      <v-flex xs12 px-3 pt-3 pb-2 v-if="showProgress">
        <v-progress-linear
          v-model="progress"
          class="theme-accent"
        ></v-progress-linear>
      </v-flex>
      <v-flex xs12 px-2>
        <v-layout row wrap v-if="imageToCrop" class="cropper-layout">
          <v-flex xs2 px-2>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="imageToCrop.name"
                  hide-details
                  class="mb-2"
                ></v-text-field>
              </v-flex>
              <v-flex
                xs12
                :class="
                  'file-size ' +
                  (maxFileSizeForUploadInvalid(
                    imageToCrop['file'] || imageToCrop.size,
                  )
                    ? 'error--text'
                    : '')
                "
                >{{ $t("size") }}:
                {{ bytesToSize(imageToCrop.size) }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs10 px-2>
            <cropper
              :style="{
                opacity: maxFileSizeForUploadInvalid(
                  imageToCrop['file'] || imageToCrop.size,
                )
                  ? 0.3
                  : 1,
              }"
              class="cropper"
              ref="cropper"
              :src="imageToCrop.dataURL"
              :stencil-props="{ aspectRatio }"
              @change="updateCrop"
            />
            <div class="delete">
              <v-btn class="ma-0" icon @click="saveCrop">
                <font-awesome-icon class="sw-accent" icon="check" />
              </v-btn>
              <v-btn class="ma-0" icon @click="imageToCrop = null">
                <font-awesome-icon class="sw-accent" icon="times" />
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
        <v-layout v-if="!imageToCrop" row wrap class="image-layout">
          <overlay-scrollbars
            v-if="uploadedFiles && uploadedFiles.length > 0"
            :options="scrollbarOptions"
            :style="{ width: '100%' }"
          >
            <v-layout row wrap>
              <v-flex
                xs12
                sm6
                md4
                lg3
                xl2
                v-for="(file, index) in uploadedFiles"
                :key="'file-' + index"
                class="file justify-center"
              >
                <v-card outlined class="card-container">
                  <v-flex class="text-center img-item">
                    <img
                      :style="{
                        opacity: maxFileSizeForUploadInvalid(file) ? 0.3 : 1,
                      }"
                      class="img"
                      v-if="file.dataURL"
                      :src="file.dataURL"
                    />
                  </v-flex>
                  <v-flex class="file-name">
                    <v-text-field
                      v-model="file.name"
                      hide-details
                      color="while"
                      loading
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    :class="
                      'file-size ' +
                      (maxFileSizeForUploadInvalid(file) ? 'error--text' : '')
                    "
                  >
                    <span>{{ $t("size") }}: {{ bytesToSize(file.size) }}</span>
                  </v-flex>
                  <div class="delete">
                    <v-btn class="ma-0" icon @click="imageToCrop = file">
                      <font-awesome-icon class="sw-accent" icon="crop" />
                    </v-btn>
                    <v-btn class="ma-0" icon @click="deleteMedia(index)">
                      <font-awesome-icon class="sw-accent" icon="trash" />
                    </v-btn>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </overlay-scrollbars>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-divider></v-divider>
      </v-flex>
      <v-flex class="xs12 px-2 py-3 text-xs-right">
        <p class="d-inline-block pr-3 pl-3 error--text" v-if="hasInvalidFile">
          {{
            $t("media_manager.every_image_should_not_more_than", {
              maxFileSizeForUpload,
            })
          }}
        </p>
        <v-btn
          round
          large
          :disabled="isUploadButtonDisable"
          :class="'theme-primary-bg theme-on-primary text-none'"
          @click="uploadMedia"
          :loading="isLoading"
        >
          {{ $t("media_manager.upload_media_btn_text") }}
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  props: {
    acceptedFiles: {
      type: String,
      default: "image/*,application/pdf",
    },
    maxFilesUpload: {
      type: Number,
      default: 1000,
    },
    maxFilesize: {
      type: Number,
      default: 5,
    },
    aspectRatio: Number,
  },
  components: {
    vueDropzone: vue2Dropzone,
    Cropper,
  },
  computed: {
    ...mapGetters(["getToken"]),
    token() {
      return this.getToken;
    },
    isUploadButtonDisable() {
      return (
        !!this.imageToCrop ||
        !this.uploadedFiles ||
        this.uploadedFiles.length === 0 ||
        this.maxFilesUpload < this.uploadedFiles.length ||
        this.hasInvalidFile
      );
    },
    hasInvalidFile() {
      return Boolean(
        (this.uploadedFiles || []).filter((file) =>
          this.maxFileSizeForUploadInvalid(file),
        ).length,
      );
    },
  },
  data: () => ({
    dropzoneOptions: {},
    showProgress: false,
    progress: 0,
    uploadedFiles: [],
    isLoading: false,
    sizes: ["Bytes", "KB", "MB", "GB", "TB"],
    scrollbarOptions: {
      scrollbars: { autoHide: "leave" },
      overflowBehavior: {
        x: "scroll",
      },
      sizeAutoCapable: true,
    },
    maxFileSizeForUpload: process.env.VUE_APP_MAX_UPLOAD_FILE_SIZE || "2MB",
    imageToCrop: null,
    imageToCropDataURL: "",
  }),
  created() {
    this.dropzoneInit();
  },
  methods: {
    updateCrop({ canvas }) {
      canvas.toBlob((blob) => {
        this.blobToDataURL(blob, (dataurl) => {
          this.imageToCropDataURL = dataurl;
        });
        this.imageToCrop.size = blob.size;
        this.imageToCrop.file = blob;
      }, this.imageToCrop.type);
    },
    saveCrop() {
      const index = this.uploadedFiles.findIndex(
        (el) => el.upload.uuid === this.imageToCrop.upload.uuid,
      );

      if (index === -1) {
        this.imageToCrop = null;
        this.imageToCropDataURL = "";
        return;
      }

      this.uploadedFiles[index] = this.imageToCrop;
      this.uploadedFiles[index].dataURL = this.imageToCropDataURL;

      this.imageToCrop = null;
      this.imageToCropDataURL = "";
    },
    bytesToSize(bytes) {
      if (bytes) {
        if (bytes === 0) return "0 Byte";
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return (
          Math.fround(bytes / Math.pow(1024, i)).toFixed(2) +
          " " +
          this.sizes[i]
        );
      }
    },
    removeAllFiles(file) {
      if (!file) this.$refs.mediaUploader.removeAllFiles();
      else this.$refs.mediaUploader.removeFile(file);
      this.$forceUpdate();
    },
    dropzoneInit() {
      this.dropzoneOptions = {
        url: process.env.VUE_APP_API_URL + "media",
        acceptedFiles: this.acceptedFiles,
        maxFiles: this.maxFilesUpload,
        maxFilesize: this.maxFilesize,
        previewsContainer: false,
        autoProcessQueue: false,
      };
    },
    vdropzoneProcessing(file) {
      this.showProgress = true;
    },
    vdropzoneUploadProgress(file, progress, bytesSent) {
      this.progress = progress;
    },
    vdropzoneSending: function (file, xhr, formData) {
      if (this.token) {
        xhr.setRequestHeader("Authorization", "Bearer " + this.token);
        formData.append("name", file.name);
      }
    },
    vdropzoneQueueComplete(file, xhr, formData) {
      this.showProgress = false;
      this.progress = 0;
    },
    vdropzoneError(file, message, xhr) {
      this.$store.dispatch("populateAjaxErrors", {
        message: message,
      });
      this.showProgress = false;
      this.progress = 0;
      this.removeAllFiles(file);
    },
    blobToDataURL(blob, callback) {
      const reader = new FileReader();

      reader.onload = (e) => {
        callback(e.target.result);
      };
      reader.readAsDataURL(blob);
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    thumbnail(file, dataUrl) {
      if (this.uploadedFiles.length >= 1) {
        delete this.uploadedFiles[0]["file"];
      }
      this.uploadedFiles.push({
        ...file,
        size: file.size,
        name: file.upload.filename,
        type: file.type,
      });
      this.removeAllFiles(file);
    },
    async uploadMedia() {
      let isError = false;
      this.isLoading = true;
      const errorMedia = [];
      for (const media of this.uploadedFiles) {
        const formData = new FormData();
        formData.append("name", media["name"]);
        formData.append(
          "file",
          media["file"] || this.dataURItoBlob(media["dataURL"]),
        );
        try {
          await this.$api.media.upload(formData);
        } catch (error) {
          errorMedia.push(media);
          isError = true;
          this.errorMessageShow(error, " fo " + media["name"]);
        }
      }
      if (!isError) {
        this.uploadedFiles = [];
        this.$emit("show-library");
      }
      this.uploadedFiles = errorMedia;
      this.isLoading = false;
    },
    deleteMedia(index) {
      this.uploadedFiles.splice(index, 1);
    },
    maxFileSizeForUploadInvalid(file) {
      if (file) {
        const imageSizeInBytes = file["file"]
          ? file["file"]["size"]
          : file.size;
        const imageSizeIndex = parseInt(
          Math.floor(Math.log(imageSizeInBytes) / Math.log(1024)),
        );
        const imageSize = Math.fround(
          imageSizeInBytes / Math.pow(1024, imageSizeIndex),
        ).toFixed(2);
        const maxFileSizeForUploadSizeIndex = this.sizes.findIndex((size) =>
          this.maxFileSizeForUpload.includes(size),
        );
        if (imageSizeIndex > maxFileSizeForUploadSizeIndex) return true;
        if (imageSizeIndex < maxFileSizeForUploadSizeIndex) return false;
        return (
          parseFloat(imageSize.toString()) >
          parseFloat(this.maxFileSizeForUpload)
        );
      }
      return true;
    },
  },
};
</script>

<style scoped>
.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
}

.file {
  position: relative;
  padding: 10px;
  border-radius: 0;
  height: 100%;
  width: 100%;
}

.delete {
  position: absolute;
  right: 20px;
  top: 20px;
}

img {
  width: 100%;
  object-fit: contain;
  object-position: center center;
  height: 160px;
  max-height: 160px;
  padding: 0;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.file-name {
  padding-left: 12px;
}

.file-name .v-text-field {
  padding-top: 4px;
}

.file-size {
  padding-left: 12px;
  padding-bottom: 14px;
  opacity: 0.6;
}
.cropper-layout .file-size {
  padding-left: 0;
  opacity: 0.6;
}

.cropper-layout {
  position: relative;
}
.cropper-layout,
.cropper,
.image-layout {
  height: 47vh;
  max-height: 470px;
}

.img-item {
  height: auto;
  display: flex;
  align-items: center;
  padding: 12px 12px 0 12px;
}

.card-container {
  background-color: #f5f5f5;
}

@media (min-height: 900px) {
  .cropper-layout,
  .cropper,
  .image-layout {
    height: 50vh;
    max-height: 480px;
  }
}
</style>
