<template>
  <v-card
    v-show="showTitle"
    flat
    class="sw-mb-3 border-radius sw-primary-bg page-title"
  >
    <v-layout style="min-height: 112px" class="row align-center sw-px-2">
      <TranslatableTooltip :content="computedTooltip">
        <div class="sw-on-primary sw-h3 page-title-title">
          {{ computedTitle }}
        </div>
      </TranslatableTooltip>
      <v-spacer></v-spacer>
      <slot name="actions"></slot>
    </v-layout>
  </v-card>
</template>

<script>
import config from "@/components/Tooltip/tooltip-config.js";

export default {
  props: {
    title: {
      type: String,
    },
    component: {
      type: String,
    },
    groupPluginId: {
      type: [String, Number],
    },
    tooltip: {
      type: String,
    },
    translatableTooltip: {
      type: String,
    },
  },
  data: () => ({
    config: config,
  }),
  computed: {
    leftMenu() {
      return this.$store.getters.getLeftMenu || {};
    },
    leftMenuDestinations() {
      return this.leftMenu.destinations || [];
    },
    currentDestination() {
      let destination = {};

      if (!this.groupPluginId) {
        destination = this.leftMenuDestinations.find(
          (el) => el.component === this.component,
        );
      } else {
        destination = this.leftMenuDestinations.find(
          (el) =>
            el.component === this.component &&
            el.group_plugin_id === Number(this.groupPluginId),
        );
      }

      return destination || {};
    },
    computedTitle() {
      return this.title ? this.title : this.currentDestination.name;
    },
    showTitle() {
      return this.computedTitle && this.computedTitle.length > 0;
    },
    computedTooltip() {
      if (this.translatableTooltip) return this.translatableTooltip;

      let message = this.config[this.tooltip] || "";

      Object.keys(this.params || {}).forEach((key) => {
        message = message.replaceAll(`:{${key}}`, this.params[key]);
      });

      return message;
    },
  },
};
</script>

<style scoped lang="scss">
.page-title {
  position: relative;
  z-index: 2;
  .page-title-title {
    line-height: 38px;
  }
}
</style>
