import axios from "axios";

const state = {
  groupCount: 0,
  activeGroup: {},
  userRolesInGroup: [],
  userGroupPolicies: [],
};

const getters = {
  activeGroup: (state) => state.activeGroup,
  userRolesInGroup: (state) => state.userRolesInGroup,
  getGroupCount: (state) => state.groupCount,
  userGroupPolicies: (state) => state.userGroupPolicies,
};

const mutations = {
  SET_GROUP_COUNT: (state, params) => {
    state.groupCount = params;
  },
  SET_ACTIVE_GROUP: (state, params) => {
    state.activeGroup = params;
  },
  SET_USER_ROLES_IN_GROUP: (state, params) => {
    state.userRolesInGroup = params;
  },
  SET_USER_GROUP_POLICIES: (state, params) => {
    state.userGroupPolicies = params;
  },
};

const actions = {
  setGroupCount: async ({ commit }, count) => {
    commit("SET_GROUP_COUNT", count);
  },
  setActiveGroup: async ({ commit }, data) => {
    commit("SET_ACTIVE_GROUP", data);
  },
  setUserRolesInGroup: async ({ commit }, roles) => {
    commit("SET_USER_ROLES_IN_GROUP", roles);
  },
  setUserGroupPolicies: async ({ commit }, groupPolicies) => {
    commit("SET_USER_GROUP_POLICIES", groupPolicies);
  },
  updateActiveGroup: async ({ commit }, id) => {
    const response = await axios
      .get(`/groups/${id}`, { params: { with_attributes: 1 } })
      .catch(() => {});

    if (!response || !response.data) {
      return;
    }

    commit("SET_ACTIVE_GROUP", response.data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
