const state = {
  groupWizardGroup: {},
  groupWizardAdministrators: [],
};

const getters = {
  groupWizardGroup: (state) => state.groupWizardGroup,
  groupWizardAdministrators: (state) => state.groupWizardAdministrators,
};

const mutations = {
  SET_GROUP_WIZARD_GROUP: (state, params) => {
    if (!params.email_sender_address) {
      params.email_sender_address = process.env.VUE_APP_EMAIL_SENDER_ADDRESS;
    }
    state.groupWizardGroup = params;
  },
  SET_GROUP_WIZARD_ADMINISTRATORS: (state, params) => {
    state.groupWizardAdministrators = params;
  },
};

const actions = {
  setGroupWizardGroup: async ({ commit }, params) => {
    commit("SET_GROUP_WIZARD_GROUP", params);
  },
  setGroupWizardAdministrators: async ({ commit }, params) => {
    commit("SET_GROUP_WIZARD_ADMINISTRATORS", params);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
