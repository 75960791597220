<template>
  <div class="relative-container" :class="{ center }">
    <template v-if="content">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <font-awesome-icon
            icon="question"
            class="tooltip-icon"
            :class="{ disabled }"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <span v-html="content" class="tooltip-content" />
      </v-tooltip>
    </template>
    <slot />
  </div>
</template>

<script>
export default {
  name: "TranslatableTooltip",
  props: {
    content: {
      type: String,
    },
    center: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.relative-container {
  position: relative;
  width: fit-content;

  &.center {
    margin: 0 auto;
  }

  .tooltip-icon {
    color: $accent;
    position: absolute;
    top: 0px;
    right: -20px;
    border: 1px solid $accent;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    padding: 2px;

    &.disabled {
      color: #c9c9c9;
      border-color: #c9c9c9;
      pointer-events: none;
    }
  }
}
</style>

<style lang="scss">
.tooltip-content {
  svg {
    fill: $accent;
  }
}
</style>
