import axios from "axios"
import {
  wrapRequest,
} from "../helpers"

const DepositTypesCreateRequest = (params) =>
  axios.post(`/groups/${params.group_id}/deposit_types`, params.model)
const DepositTypesListRequest = (params) =>
  axios.get(`/groups/${params.group_id}/deposit_types`, params)
const DepositTypesShowRequest = (params) =>
  axios.get(`/groups/${params.group_id}/deposit_types/${params.deposit_type_id}`, params)
const DepositTypesUpdateRequest = (params) =>
  axios.post(`/groups/${params.group_id}/deposit_types/${params.deposit_type_id}`, params.model)
const DepositTypesDeleteRequest = (params) =>
  axios.delete(`/groups/${params.group_id}/deposit_types/${params.deposit_type_id}`, params)

export const wrappedDepositTypesCreateRequest = wrapRequest(DepositTypesCreateRequest)
export const wrappedDepositTypesListRequest = wrapRequest(DepositTypesListRequest)
export const wrappedDepositTypesShowRequest = wrapRequest(DepositTypesShowRequest)
export const wrappedDepositTypesUpdateRequest = wrapRequest(DepositTypesUpdateRequest)
export const wrappedDepositTypesDeleteRequest = wrapRequest(DepositTypesDeleteRequest)
