<template>
  <div class="media-manager">
    <v-layout row wrap>
      <v-flex v-if="label" xs12 class="mb-2 sw-h5">
        {{ label }}
      </v-flex>
      <v-flex
        xs12
        v-if="computedValue && computedValue.length > 0"
        class="pt-5"
      >
        <v-layout row wrap>
          <v-flex
            v-for="(item, itemIndex) in computedValue"
            :key="'item-' + itemIndex"
            class="mx-auto"
            :class="computedValue.length > 3 ? 'xs2' : 'xs3'"
          >
            <v-card flat class="fill-height" style="border-radius: 0">
              <v-btn
                fab
                class="ma-0 sw-accent-bg sw-on-accent small-fab"
                @click="removeMedia(item)"
              >
                <v-icon>close</v-icon>
              </v-btn>
              <v-img
                v-if="item && isImageType(item.mime_type)"
                :src="item.presets.thumb"
                aspect-ratio="1"
                class="grey lighten-2"
              ></v-img>
              <div v-if="item && isPdfType(item.mime_type)" class="text-center">
                <img
                  :src="require(`@/assets/images/pdf.png`)"
                  style="max-height: 76px"
                />
                <div class="media-name">{{ item.name }}</div>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="showSelectBtn" xs12 class="mb-3 mt-5">
        <v-btn
          round
          class="mx-0 sw-accent-bg sw-on-accent text-none"
          @click="openMediaManager"
          >{{ $t("select") }}</v-btn
        >
      </v-flex>
      <v-flex v-if="!isFilesSelected" xs12 class="caption grey--text">
        {{ noMediaSelected || $t("media_manager.no_image_selected") }}
      </v-flex>
    </v-layout>
    <v-dialog v-model="mediaDialog" width="1200px" persistent>
      <v-card color="white lighten-4 rounded" flat>
        <v-layout row wrap v-if="mediaDialog">
          <v-flex xs12 class="pl-3 pr-1">
            <v-layout row wrap align-center>
              <v-flex xs3 title>{{ $t("media_manager.media_manager") }}</v-flex>
              <v-flex xs6>
                <v-tabs v-model="mediaTabs" show-arrows height="60">
                  <v-tab
                    v-for="(item, tabIndex) in items"
                    :key="'media-tab-' + tabIndex"
                  >
                    <span class="text-none tab-text">{{ item.name }}</span>
                  </v-tab>
                </v-tabs>
              </v-flex>
              <v-flex xs3 text-xs-right>
                <v-btn icon class="ma-0" @click="mediaDialog = false">
                  <v-icon small>close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12>
            <v-tabs-items v-model="mediaTabs" class="custom-tabs-items">
              <v-tab-item
                v-for="(item, tabItemIndex) in items"
                :key="'media-tab-item-' + tabItemIndex"
              >
                <template v-if="mediaTabs == tabItemIndex">
                  <media-upload
                    v-if="item.component == 'media-upload'"
                    :accepted-files="acceptedFiles"
                    :maxFilesUpload="maxFilesUpload"
                    @show-library="mediaTabs = 1"
                  ></media-upload>
                  <media-library
                    v-if="item.component == 'media-library'"
                    :value.sync="computedValue"
                    :maxFilesInsert="maxFilesInsert"
                    :media-type="mediaType"
                    :image-preview="imagePreview"
                    :image-preview-background-size="imagePreviewBackgroundSize"
                    @show-upload="mediaTabs = 0"
                    @media-insert="insertMedia"
                  ></media-library>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MediaUpload from "@/components/Media/MediaUpload.vue";
import MediaLibrary from "@/components/Media/MediaLibrary.vue";

export default {
  props: {
    value: [Array, Object],
    maxFilesUpload: {
      type: Number,
      default: 1000,
    },
    maxFilesInsert: {
      type: Number,
      default: 1,
    },
    mediaType: {
      type: String,
      default: null,
    },
    noMediaSelected: {
      type: String,
    },
    label: {
      type: String,
      default: "Image:",
    },
    imagePreview: {
      type: Boolean,
    },
    imagePreviewBackgroundSize: {
      type: String,
    },
  },
  components: {
    "media-upload": MediaUpload,
    "media-library": MediaLibrary,
  },
  data: () => ({
    mediaDialog: false,
    mediaTabs: 1,
  }),
  computed: {
    items() {
      return [
        {
          name: this.$t("upload"),
          component: "media-upload",
        },
        {
          name: this.$t("media_manager.media_library"),
          component: "media-library",
        },
      ];
    },
    computedValue() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          return this.value;
        } else {
          return new Array(this.value);
        }
      }
      return [];
    },
    showSelectBtn() {
      return (
        this.maxFilesInsert >= 1 ||
        !this.computedValue ||
        this.computedValue.length === 0
      );
    },
    isFilesSelected() {
      return this.computedValue && this.computedValue.length > 0;
    },
    acceptedFiles() {
      if (this.mediaType === "images") {
        return "image/*";
      }

      if (this.mediaType === "files") {
        return "application/pdf";
      }

      return "";
    },
  },
  methods: {
    openMediaManager() {
      this.mediaTabs = 1;
      this.mediaDialog = true;
    },
    isPdfType(mimeType) {
      return mimeType === "application/pdf";
    },
    isImageType(mimeType) {
      return (
        mimeType === "image/png" ||
        mimeType === "image/jpeg" ||
        mimeType === "image/gif"
      );
    },
    insertMedia(val) {
      // select one file for both single and default
      if (this.maxFilesInsert === 1) {
        this.$emit("input", val[0]);
      } else {
        this.$emit("input", val);
      }
      this.mediaDialog = false;
    },
    removeMedia(val) {
      if (this.maxFilesInsert === 1) {
        this.$emit("input", null);
      } else {
        this.$emit(
          "input",
          this.computedValue.filter((item) => item.id !== val.id),
        );
      }
    },
  },
};
</script>

<style lang="scss">
.custom-tabs-items .v-window__container {
  height: auto !important;
}

.small-fab {
  position: absolute !important;
  height: 24px !important;
  width: 24px !important;
  top: -12px !important;
  right: -12px !important;
  z-index: 1;

  .v-icon {
    font-size: 12px !important;
  }
}

.media-name {
  min-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-tabs__slider {
  &.accent {
    background-color: $accent !important;
    border-color: $accent !important;
    margin: 0 4px;
  }
}

.tab-text {
  padding-left: 4px;
  padding-right: 4px;
}
</style>
