const state = {
  signupWizardPlugin: {},
  signupWizardCategory: {},
  signupWizardAttributes: [],
  signupWizardAttributeTags: [],
  signupWizardMessageTemplate: [],
  signupWizardIonicAppSidemenu: {},
};

const getters = {
  signupWizardPlugin: (state) => state.signupWizardPlugin,
  signupWizardCategory: (state) => state.signupWizardCategory,
  signupWizardAttributes: (state) => state.signupWizardAttributes,
  signupWizardAttributeTags: (state) => state.signupWizardAttributeTags,
  signupWizardMessageTemplate: (state) => state.signupWizardMessageTemplate,
  signupWizardIonicAppSidemenu: (state) => state.signupWizardIonicAppSidemenu,
};

const mutations = {
  SET_SIGNUP_WIZARD_PLUGIN: (state, params) => {
    state.signupWizardPlugin = params;
  },
  SET_SIGNUP_WIZARD_CATEGORY: (state, params) => {
    state.signupWizardCategory = params;
  },
  SET_SIGNUP_WIZARD_ATTRIBUTES: (state, params) => {
    state.signupWizardAttributes = params;
  },
  SET_SIGNUP_WIZARD_ATTRIBUTE_TAGS: (state, params) => {
    state.signupWizardAttributeTags = params;
  },
  SET_SIGNUP_WIZARD_MESSAGE_TEMPLATE: (state, params) => {
    state.signupWizardMessageTemplate = params;
  },
  SET_SIGNUP_WIZARD_IONIC_APP_SIDEMENU: (state, data) => {
    state.signupWizardIonicAppSidemenu = data;
  },
};

const actions = {
  setSignupWizardPlugin: async ({ commit }, params) => {
    commit("SET_SIGNUP_WIZARD_PLUGIN", params)
  },
  setSignupWizardCategory: async ({ commit }, params) => {
    commit("SET_SIGNUP_WIZARD_CATEGORY", params)
  },
  setSignupWizardAttributes: async ({ commit }, params) => {
    commit("SET_SIGNUP_WIZARD_ATTRIBUTES", params)
  },
  setSignupWizardAttributeTags: async ({ commit }, params) => {
    commit("SET_SIGNUP_WIZARD_ATTRIBUTE_TAGS", params)
  },
  setSignupWizardMessageTemplate: async ({ commit }, params) => {
    commit("SET_SIGNUP_WIZARD_MESSAGE_TEMPLATE", params)
  },
  setSignupWizardIonicAppSidmenu: async ({ commit, dispatch }, data) => {
    commit("SET_SIGNUP_WIZARD_IONIC_APP_SIDEMENU", data)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
