import axios from "axios"
import {
  wrapRequest,
} from "../helpers"

const DepositsCreateRequest = (params) =>
  axios.post(`/groups/${params.group_id}/deposits`, params.model)
const DepositsListRequest = (params) =>
  axios.get(`/groups/${params.group_id}/deposits`, params)
const DepositsUpdateRequest = (params) =>
  axios.post(`/groups/${params.group_id}/deposits/${params.deposit_id}`, params.model)
const DepositsDeleteRequest = (params) =>
  axios.delete(`/groups/${params.group_id}/deposits/${params.deposit_id}`)
const DepositsHistoryRequest = (params) =>
  axios.get(`/groups/${params.group_id}/deposits/history`, params)

export const wrappedDepositsCreateRequest = wrapRequest(DepositsCreateRequest)
export const wrappedDepositsListRequest = wrapRequest(DepositsListRequest)
export const wrappedDepositsUpdateRequest = wrapRequest(DepositsUpdateRequest)
export const wrappedDepositsDeleteRequest = wrapRequest(DepositsDeleteRequest)
export const wrappedDepositsHistoryRequest = wrapRequest(DepositsHistoryRequest)

const CheckedinCountRequest = (params) =>
  axios.get(`/groups/${params.group_id}/deposits/count`, params)

export const wrappedCheckedinCountRequest = wrapRequest(CheckedinCountRequest)
