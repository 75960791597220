const state = {
  groupUserAttributeWizardPlugin: {},
  groupUserAttributeWizardAttributes: [],
  groupUserAttributeWizardAttributesWithoutPlugin: [],
};

const getters = {
  groupUserAttributeWizardPlugin: (state) =>
    state.groupUserAttributeWizardPlugin,
  groupUserAttributeWizardAttributes: (state) =>
    state.groupUserAttributeWizardAttributes,
  groupUserAttributeWizardAttributesWithoutPlugin: (state) =>
    state.groupUserAttributeWizardAttributesWithoutPlugin,
};

const mutations = {
  SET_GROUP_USER_ATTRIBUTE_WIZARD_PLUGIN: (state, params) => {
    state.groupUserAttributeWizardPlugin = params;
  },
  SET_GROUP_USER_ATTRIBUTE_WIZARD_ATTRIBUTES: (state, params) => {
    state.groupUserAttributeWizardAttributes = params;
  },
  SET_GROUP_USER_ATTRIBUTE_WIZARD_ATTRIBUTES_WITHOUT_PLUGIN: (
    state,
    params,
  ) => {
    state.groupUserAttributeWizardAttributesWithoutPlugin = params;
  },
};

const actions = {
  setGroupUserAttributeWizardPlugin: async ({ commit }, params) => {
    commit("SET_GROUP_USER_ATTRIBUTE_WIZARD_PLUGIN", params);
  },
  setGroupUserAttributeWizardAttributes: async ({ commit }, params) => {
    commit("SET_GROUP_USER_ATTRIBUTE_WIZARD_ATTRIBUTES", params);
  },
  setGroupUserAttributeWizardAttributesWithoutPlugin: async (
    { commit },
    params,
  ) => {
    commit("SET_GROUP_USER_ATTRIBUTE_WIZARD_ATTRIBUTES_WITHOUT_PLUGIN", params);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
