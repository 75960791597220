<template>
  <div v-if="languages.length">
    <v-select
      v-model="language"
      item-value="value"
      :items="languages"
      :label="$t('language')"
      hide-details
      :disabled="languages.length === 1"
    >
      <template slot="selection" slot-scope="data">
        <span :class="`mr-2 flag-icon flag-icon-${data.item.iso2}`"></span>
        <span>{{ data.item.name }}</span>
      </template>
      <template slot="item" slot-scope="data">
        <span :class="`mr-2 flag-icon flag-icon-${data.item.iso2}`"></span>
        <span class="slot-item">{{ data.item.name }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import "flag-icon-css/css/flag-icon.css";
import { languageIcons } from "@/language-icons.json";

export default {
  computed: {
    envLanguages() {
      return process.env.VUE_APP_LANGUAGES
        ? process.env.VUE_APP_LANGUAGES.split(",")
        : [];
    },
    languages() {
      return this.envLanguages.map((lang) => {
        const icon = languageIcons.find((el) => el.key === lang);

        return {
          name: this.$t(`common.${lang}_label`),
          value: lang,
          iso2: icon && icon.iso2 ? icon.iso2 : "",
        };
      });
    },
    language: {
      get() {
        return this.$store.getters.appLanguage;
      },
      set(val) {
        this.$store.commit("SET_APP_LANGUAGE", val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.language && this.envLanguages.includes(this.language)) {
        return;
      }

      if (!this.languages[0]) {
        return;
      }

      this.language = this.languages[0].value || "";
    });
  },
};
</script>

<style scoped lang="scss"></style>
