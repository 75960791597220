import axios from "axios";

export const groupUserAttributeTags = {
  create: (groupId, specs) =>
    axios.post(`/groups/${groupId}/users/attributes/tags`, specs),
  list: (groupId, params) =>
    axios.get(`/groups/${groupId}/users/attributes/tags`, { params }),
  update: (groupId, id, specs) =>
    axios.post(`/groups/${groupId}/users/attributes/tags/${id}`, specs),
  sort: (groupId, specs) =>
    axios.post(`/groups/${groupId}/users/attributes/tags/sort`, specs),
  delete: (groupId, id) =>
    axios.delete(`/groups/${groupId}/users/attributes/tags/${id}`),
};
