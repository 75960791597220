const state = {
  audienceFilters: [],
  audienceFilterAll: false,
  platformFilters: [],
  roleFilters: [],
  attributesFilters: [],
  ticketTemplateFilters: [],
};

const getters = {
  audienceFilters: (state) => state.audienceFilters,
  audienceFilterAll: (state) => state.audienceFilterAll,
  platformFilters: (state) => state.platformFilters,
  roleFilters: (state) => state.roleFilters,
  attributesFilters: (state) => state.attributesFilters,
  ticketTemplateFilters: (state) => state.ticketTemplateFilters,
};

const mutations = {
  SET_AUDIENCE_FILTERS: (state, data) => {
    state.audienceFilters = data;
  },
  SET_AUDIENCE_FILTER_ALL: (state, data) => {
    state.audienceFilterAll = data;
  },
  SET_PLATFORM_FILTERS: (state, data) => {
    state.platformFilters = data;
  },
  SET_ROLE_FILTERS: (state, data) => {
    state.roleFilters = data;
  },
  SET_ATTRIBUTES_FILTERS: (state, data) => {
    state.attributesFilters = data;
  },
  SET_TICKET_TEMPLATE_FILTERS: (state, data) => {
    state.ticketTemplateFilters = data;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
