import {
  wrappedDepositTypesListRequest,
  wrappedDepositTypesCreateRequest,
  wrappedDepositTypesShowRequest,
  wrappedDepositTypesUpdateRequest,
  wrappedDepositTypesDeleteRequest,
} from "../requests/depositTypes";

const state = {
  depositTypes: [],
};
const getters = {
  getDepositTypes: (state) => state.depositTypes,
};
const mutations = {
  SET_DEPOSIT_TYPES: (state, deposit_types) =>
    (state.depositTypes = deposit_types),
};
const actions = {
  createDepositTypes: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositTypesCreateRequest(params);
    if (data) {
      return data;
    }
  },
  listDepositTypes: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositTypesListRequest(params);
    if (data) {
      commit("SET_DEPOSIT_TYPES", data.data);
      return data;
    }
  },
  showDepositTypes: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositTypesShowRequest(params);
    if (data) {
      return data;
    }
  },
  updateDepositTypes: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositTypesUpdateRequest(params);
    if (data) {
      return data;
    }
  },
  deleteDepositTypes: async ({ commit, dispatch }, params) => {
    const data = await wrappedDepositTypesDeleteRequest(params);
    if (data) {
      return data;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
