import axios from "axios";

export const authStripe = {
  intent: () => axios.get(`/auth/stripe/intent`),
  create: (specs) => axios.post(`/auth/stripe/method`, specs),
  deposit: (specs) => axios.post(`/auth/stripe/deposit`, specs),
  list: (params) => axios.get(`/auth/stripe/method`, { params }),
  invoices: (params) => axios.get(`/auth/stripe/invoices`, { params }),
  delete: () => axios.delete(`/auth/stripe/method`),
};
