import axios from "axios";

export const groupTickets = {
  list: (groupId, params) => axios.get(`/groups/${groupId}/tickets`, { params }),
  get: (groupId, id, params) => axios.get(`/groups/${groupId}/tickets/${id}`, { params }),
  check: (groupId, params) => axios.get(`/groups/${groupId}/tickets/check`, { params }),
  checkin: (groupId, params) => axios.get(`/groups/${groupId}/tickets/checkin`, { params }),
  checkout: (groupId, id, params) => axios.get(`/groups/${groupId}/tickets/${id}/checkout`, { params }),
  generate: (groupId, specs) => axios.post(`/groups/${groupId}/tickets`, specs),
  delete_pdf: (groupId, id) => axios.delete(`/groups/${groupId}/tickets/${id}/delete_pdf`),
};
