import Vue from "vue";

Vue.mixin({
  computed: {
    vuexloading() {
      return this.$store.getters.vuexloading;
    },
  },
  methods: {
    anyVuexLoading() {
      return this.vuexloading.some((el) => el);
    },
    isVuexLoading(loaders) {
      if (!loaders) return;

      const loadersArray = loaders.split(",");

      for (const loader of loadersArray) {
        if (this.vuexloading.includes(loader)) {
          return true;
        }
      }

      return;
    },
    startVuexLoading(loader) {
      this.$store.dispatch("startLoading", loader);
    },
    endVuexLoading(loader) {
      this.$store.dispatch("endLoading", loader);
    },
    errorMessageShow(error, additionalMessage="") {
      if(error && error.response && error.response.status === 422){
          this.$store.dispatch("addErrorNotification", {
              message: error.response.data.error.message + additionalMessage,
          });
      } else {
          this.$store.dispatch("addErrorNotification", {
          message: this.$t("internalServerError"),
          });
      }
    },
  },
});
