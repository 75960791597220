const state = {
  ticketTemplateWizardPlugin: {},
  ticketTemplateWizardTicket: {},
  ticketTemplateWizardEmail: {},
  ticketTemplateWizardMenu: {},
};

const getters = {
  ticketTemplateWizardPlugin: (state) => state.ticketTemplateWizardPlugin,
  ticketTemplateWizardTicket: (state) => state.ticketTemplateWizardTicket,
  ticketTemplateWizardEmail: (state) => state.ticketTemplateWizardEmail,
  ticketTemplateWizardMenu: (state) => state.ticketTemplateWizardMenu,
};

const mutations = {
  SET_TICKET_TEMPLATE_WIZARD_PLUGIN: (state, data) => {
    state.ticketTemplateWizardPlugin = data;
  },
  SET_TICKET_TEMPLATE_WIZARD_TICKET: (state, data) => {
    state.ticketTemplateWizardTicket = data;
  },
  SET_TICKET_TEMPLATE_WIZARD_EMAIL: (state, data) => {
    state.ticketTemplateWizardEmail = data;
  },
  SET_TICKET_TEMPLATE_WIZARD_MENU: (state, data) => {
    state.ticketTemplateWizardMenu = data;
  },
};

const actions = {
  setTicketTemplateWizardPlugin: async ({ commit }, data) => {
    commit("SET_TICKET_TEMPLATE_WIZARD_PLUGIN", data)
  },
  setTicketTemplateWizardTicket: async ({ commit }, data) => {
    commit("SET_TICKET_TEMPLATE_WIZARD_TICKET", data)
  },
  setTicketTemplateWizardEmail: async ({ commit }, data) => {
    commit("SET_TICKET_TEMPLATE_WIZARD_EMAIL", data)
  },
  setTicketTemplateWizardMenu: async ({ commit }, data) => {
    commit("SET_TICKET_TEMPLATE_WIZARD_MENU", data)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
