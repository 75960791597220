<template>
  <div>
    <div class="pb-2">
      <TranslatableTooltip :content="$t('quill.tags_label_tooltip')">
        <div class="sw-h5">
          {{ $t("quill.tags_label") }}
        </div>
      </TranslatableTooltip>
    </div>
    <v-tabs v-model="tab" slider-color="sw-accent-bg" class="mb-3">
      <v-tab v-for="(category, i) in categories" :key="`tab-${i}`">
        <font-awesome-icon class="sw-accent" :icon="category.icon" />
      </v-tab>
    </v-tabs>
    <div v-if="tab === 1" class="mb-2">
      <v-text-field
        v-model="search"
        type="text"
        :label="$t('search')"
        :placeholder="$t('typeToSearchPlaceholder')"
        hide-details
      ></v-text-field>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(category, i) in categories" :key="`tab-item-${i}`">
        <v-card flat>
          <div v-if="category.title">
            <strong>{{ category.title }}:</strong>
          </div>
          <v-card-text class="py-1 px-0">
            <v-btn
              v-for="(attribute, i) in filteredShortcodes(category.tags)"
              :key="`attribute-${i}`"
              block
              small
              class="mx-0 mb-0 mt-1 sw-accent-bg sw-on-accent text-none"
              :disabled="isLoading"
              @click="includeShortcode(attribute)"
            >
              <div class="text-ellipsis">
                {{ attribute.text }}
              </div>
            </v-btn>
            <div
              v-if="filteredShortcodes(category.tags).length === 0"
              class="grey--text"
            >
              <template v-if="!isLoading">
                {{ $t("nothingFound") }}
              </template>
              <template v-if="isLoading">
                {{ $t("common.loading") }}
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <QuestionsShortcodeModal ref="questionsShortcodeModal" />
    <LinkShortcodeModal ref="linkShortcodeModal" />
  </div>
</template>

<script>
import QuestionsShortcodeModal from "@/components/Quill/QuestionsShortcodeModal.vue";
import LinkShortcodeModal from "@/components/Quill/LinkShortcodeModal.vue";

export default {
  props: {
    showLoginLink: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    tab: 0,
    search: null,
    groupPluginList: [],
    userAttributes: [],
    ticketTemplates: [],
    perPage: 1000,
    visibleTagCount: 5,
    expires: 20160,
  }),
  computed: {
    defaultDomain() {
      return process.env.VUE_APP_DEFAULT_DOMAIN;
    },
    group() {
      return this.$store.getters.activeGroup;
    },
    groupId() {
      return this.$route.params.group_id;
    },
    hostName() {
      return `https://[group-property property=subdomain group_id/].${this.defaultDomain}/#/`;
    },
    tokenQuery() {
      return `token=[user_login_token user_id type=deeplink expires=${this.expires}/]`;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    defaultUserAttributes() {
      return [
        {
          name: "name",
          value: "first_name",
          tag: "property",
          text: this.$t("first-name-tag"),
          instance: "user_id",
        },
        {
          name: "name",
          value: "last_name",
          tag: "property",
          text: this.$t("last-name-tag"),
          instance: "user_id",
        },
        {
          name: "name",
          value: "phone",
          tag: "property",
          text: this.$t("phone-tag"),
          instance: "user_id",
        },
        {
          name: "name",
          value: "email",
          tag: "property",
          text: this.$t("email-tag"),
          instance: "user_id",
        },
        {
          name: "link",
          value: `${this.hostName}?${this.tokenQuery}`,
          tag: "link",
          text: this.$t("shortcode.login-link"),
          instance: "link",
          details: {
            title: this.$t("shortcode.login-link"),
            text: this.$t("shortcode.click-to-registration"),
          },
        },
      ];
    },
    signupAnswers() {
      const signupPlugin = this.groupPluginList.find(
        (el) => el.prefix === "signup",
      );

      if (!signupPlugin || !signupPlugin.id) return {};

      return {
        name: "link",
        value: `${this.hostName}answers/${signupPlugin.id}?${this.tokenQuery}`,
        tag: "link",
        text: signupPlugin.name,
        instance: "link",
        details: {
          title: this.$t("shortcode.answers_title"),
          text: this.$t("shortcode.answers_text"),
        },
      };
    },
    quizAnswers() {
      const quizPlugins = this.groupPluginList.filter(
        (el) => el.prefix === "questionwizard",
      );

      if (!quizPlugins || !quizPlugins.length) return [];

      return quizPlugins.map((el) => ({
        name: "link",
        value: `${this.hostName}answers/${el.id}?${this.tokenQuery}`,
        tag: "link",
        text: el.name,
        instance: "link",
        details: {
          title: this.$t("shortcode.answers_title"),
          text: this.$t("shortcode.answers_text"),
        },
      }));
    },
    groupAttributes() {
      const attributes = [];

      if (this.group && this.group.name) {
        attributes.push({
          name: "property",
          value: "name",
          tag: "group-property",
          text: "Event name",
          instance: "group_id",
        });
      }

      const signupPlugin = this.groupPluginList.find(
        (el) => el.prefix === "signup",
      );

      if (signupPlugin && signupPlugin.id) {
        attributes.push({
          name: "plugin_id",
          value: signupPlugin.id,
          tag: "payment-overview",
          text: "Payment overview",
          instance: "user_id",
        });
      }

      return attributes;
    },
    answers() {
      return [this.signupAnswers, ...this.quizAnswers].filter((el) => el.value);
    },
    categories() {
      return [
        {
          key: "default-user-attributes",
          icon: ["fa", "user"],
          title: "",
          tags: this.defaultUserAttributes,
        },
        {
          key: "user-attributes",
          icon: ["fa", "tshirt"],
          title: "",
          tags: this.userAttributes,
        },
        {
          key: "group-attributes",
          icon: ["fa", "tasks"],
          title: "",
          tags: this.groupAttributes,
        },
        {
          key: "user-answers",
          icon: ["far", "poll-people"],
          title: this.$t("shortcode.answers_title"),
          tags: this.answers,
        },
        {
          key: "ticket-templates",
          icon: "ticket-alt",
          title: "",
          tags: this.ticketTemplates,
        },
      ];
    },
    activeTabKey() {
      return this.categories[this.tab].key;
    },
  },
  components: {
    QuestionsShortcodeModal,
    LinkShortcodeModal,
  },
  watch: {
    activeTabKey: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;

        this.fetchDataByKey(newVal);
      },
    },
    appContentLanguage: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;

        this.fetchDataByKey(this.activeTabKey);
      },
    },
  },
  methods: {
    fetchDataByKey(key) {
      if (!key) return;

      if (key === "default-user-attributes") {
        this.getLoginLinkExpire();
      }

      if (key === "user-attributes") {
        this.listGroupUserAttributes();
      }

      if (key === "group-attributes") {
        this.listGroupPlugins();
      }

      if (key === "user-answers") {
        this.listGroupPlugins();
      }

      if (key === "ticket-templates") {
        this.listGroupTicketTemplates();
      }
    },
    async getLoginLinkExpire() {
      try {
        this.isLoading = true;

        const response = await this.$api.settings.public(
          "login_link_expiration_minutes",
        );

        this.isLoading = false;

        this.expires = response.data.data.value;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async listGroupPlugins() {
      if (!this.groupId) return;

      try {
        this.isLoading = true;

        const params = [
          this.groupId,
          {
            lang: this.appContentLanguage,
            per_page: this.perPage,
          },
        ];

        const response = await this.$api.groupPlugins.list(...params);

        this.isLoading = false;

        this.groupPluginList = response.data.data;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async listGroupUserAttributes() {
      if (!this.groupId) return;

      try {
        this.isLoading = true;

        const groupPluginListParams = [
          this.groupId,
          {
            lang: this.appContentLanguage,
            per_page: this.perPage,
          },
        ];

        const groupPluginListResponse = await this.$api.groupPlugins.list(
          ...groupPluginListParams,
        );

        this.groupPluginList = groupPluginListResponse.data.data;

        const groupUserAttributesParams = [
          this.groupId,
          {
            lang: this.appContentLanguage,
            per_page: this.perPage,
          },
        ];

        const signupPlugin = this.groupPluginList.find(
          (el) => el.prefix === "signup",
        );

        const groupUserAttributesResponse =
          await this.$api.groupUserAttributes.list(
            ...groupUserAttributesParams,
          );

        this.isLoading = false;

        this.userAttributes = groupUserAttributesResponse.data.data.map(
          (el) => ({
            name: "id",
            value: el.id.toString(),
            tag: "attribute",
            text: el.name,
            instance: "user_id",
            ...(signupPlugin &&
              signupPlugin.id == el.group_plugin_id && { meta: { ...el } }),
          }),
        );
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async listGroupTicketTemplates() {
      if (!this.groupId) return;

      try {
        this.isLoading = true;

        const params = [
          this.groupId,
          {
            lang: this.appContentLanguage,
            per_page: this.perPage,
          },
        ];

        const response = await this.$api.groupTicketTemplates.list(...params);

        this.isLoading = false;

        if (!response.data.data) return;

        this.ticketTemplates = response.data.data.map((el) => ({
          name: "ticket_template_id",
          value: el.id,
          tag: "ticket",
          text: el.name,
          instance: "user_id",
        }));
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    filteredShortcodes(items) {
      if (!items) return [];

      items = items.filter((el) => {
        if (el.name === "link" && !this.showLoginLink) return false;
        return !(el.name === "link" && !this.group.subdomain);
      });

      if (!this.search || this.tab !== 1) {
        return items.slice(0, this.visibleTagCount);
      }

      return items
        .filter((el) =>
          el.text.toString().toLowerCase().includes(this.search.toLowerCase()),
        )
        .slice(0, this.visibleTagCount);
    },
    async includeShortcode(value) {
      let val = { ...value };

      if (val.tag === "link") {
        const params = [val.details.title, val.details.text];

        const response = await this.$refs.linkShortcodeModal.open(...params);

        if (!response) return;

        const link = {
          text: response,
          href: val.value,
        };

        this.$emit("insert-quill-link", link);

        return;
      }

      if (
        val.tag === "attribute" &&
        val.meta &&
        this.showLoginLink &&
        this.group.subdomain
      ) {
        const response = await this.$refs.questionsShortcodeModal.open(
          val.meta,
        );

        if (!response) return;

        if (!response.query) {
          val.text = response.text;

          this.$emit("include-shortcode", val);
        } else {
          const link = {
            text: response.text,
            href: `${this.hostName}?${response.query}&${this.tokenQuery}`,
          };

          this.$emit("insert-quill-link", link);
        }

        return;
      }

      this.$emit("include-shortcode", val);
    },
  },
};
</script>

<style scoped lang="scss"></style>
