import axios from "axios";

export const auth = {
  register: (specs) => axios.post("/auth/register", specs),
  login: (params) => axios.get("/auth/login", { params }),
  me: (params) => axios.get("/auth/me", { params }),
  permissions: (params) => axios.get("/auth/permissions", { params }),
  update: (specs) => axios.post("/auth/update", specs),
  group_register: (specs) => axios.post("/auth/groups/register", specs),
  reminder: (params) => axios.get("/auth/reminder", { params }),
  balance: (params) => axios.get(`/auth/balance`, { params }),
  subscribe: (params) => axios.get(`/auth/subscribe`, { params }),
  logout: () => axios.get("/auth/logout"),
};
