const state = {
  appPageWizardArticle: {},
  appPageWizardMenu: {},
};

const getters = {
  appPageWizardArticle: (state) => state.appPageWizardArticle,
  appPageWizardMenu: (state) => state.appPageWizardMenu,
};

const mutations = {
  SET_APP_PAGE_WIZARD_ARTICLE: (state, data) => {
    state.appPageWizardArticle = data;
  },
  SET_APP_PAGE_WIZARD_MENU: (state, data) => {
    state.appPageWizardMenu = data;
  },
};

const actions = {
  setAppPageWizardArticle: async ({ commit, dispatch }, data) => {
    commit("SET_APP_PAGE_WIZARD_ARTICLE", data)
  },
  setAppPageWizardMenu: async ({ commit, dispatch }, data) => {
    commit("SET_APP_PAGE_WIZARD_MENU", data)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
