const state = {
  depositsHistoryLog: [],
}
const getters = {
  getDepositsHistoryLog: (state) => state.depositsHistoryLog,
}
const mutations = {
  ADD_DEPOSIT_LOG: (state, params) => {
    if (params) {
      if (state.depositsHistoryLog.length > 19) {
        state.depositsHistoryLog.pop()
      }
      state.depositsHistoryLog.unshift(params)
    }
  },
  CLEAR_DEPOSITS_HISTORY_LOG: (state) => {
    state.depositsHistoryLog = []
  },
}
const actions = {
  addDepositHistoryLog: async ({ commit, dispatch }, params) => {
    commit("ADD_DEPOSIT_LOG", params)
  },
  clearDepositsHistoryLog: async ({ commit, dispatch }) => {
    commit("CLEAR_DEPOSITS_HISTORY_LOG")
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
