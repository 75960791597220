const state = {
  groupMessageTemplateWizardTemplate: {},
};

const getters = {
  groupMessageTemplateWizardTemplate: (state) => state.groupMessageTemplateWizardTemplate,
};

const mutations = {
  SET_GROUP_MESSAGE_TEMPLATE_WIZARD_TEMPLATE: (state, data) => {
    state.groupMessageTemplateWizardTemplate = data;
  },
};

const actions = {
  setGroupMessageTemplateWizardTemplate: async ({ commit, dispatch }, data) => {
    commit("SET_GROUP_MESSAGE_TEMPLATE_WIZARD_TEMPLATE", data)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
