const state = {
  token: "",
  user: {},
  permissions: [],
};

const getters = {
  getToken: (state) => {
    return state.token;
  },
  currentUser: (state) => {
    return state.user;
  },
  currentUserPermissions: (state) => {
    return state.permissions;
  },
};

const mutations = {
  SET_TOKEN: (state, token) => (state.token = token),
  SET_USER: (state, user) => (state.user = user),
  SET_USER_PERMISSIONS: (state, permissions) => (state.permissions = permissions),
};

const actions = {
  setCurrentUserPermissions: async ({ commit }, permissions) => {
    commit("SET_USER_PERMISSIONS", permissions);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
