<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 px-3 pt-1 pb-3>
            <v-layout class="row wrap text-xs-right align-center">
              <v-spacer />
              <v-flex xs3>
                <v-text-field
                  v-model="search"
                  :label="$t('search')"
                  type="text"
                  hide-details
                  append-icon="search"
                  class=""
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-layout row wrap xs12 class="image-layout">
              <overlay-scrollbars
                :options="scrollbarOptions"
                :style="{ width: '100%' }"
              >
                <v-layout
                  row
                  wrap
                  xs12
                  v-if="ready && media.length > 0"
                  class="image-wrap"
                >
                  <v-flex
                    xs12
                    sm6
                    md4
                    lg3
                    xl2
                    v-for="(file, index) in media"
                    :key="'media-item-' + index"
                    class="file justify-center"
                  >
                    <v-card
                      outlined
                      class="card-container cursor-pointer"
                      @click="selectMedia($event, index, file.id)"
                    >
                      <v-flex class="text-center img-item">
                        <img
                          v-if="typeCheck(file.mime_type, 'img')"
                          class="img"
                          :src="getThumb(file)"
                        />
                        <img
                          v-else
                          class="img"
                          :src="require(`@/assets/images/pdf.png`)"
                        />
                      </v-flex>
                      <v-flex class="file-name">
                        <v-text-field
                          v-model="file.name"
                          hide-details
                          @input="updateMedia(file)"
                          color="while"
                          loading
                        ></v-text-field>
                      </v-flex>
                      <v-flex class="file-size">
                        <span
                          >{{ $t("size") }}: {{ bytesToSize(file.size) }}</span
                        >
                      </v-flex>
                      <span class="select-media">
                        <v-icon color="accent" medium>
                          {{
                            isSelected(file.id)
                              ? "check_box"
                              : "check_box_outline_blank"
                          }}
                        </v-icon>
                      </span>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 text-xs-center v-if="loadMore">
                    <v-pagination
                      class="pa-2"
                      v-model="page"
                      :length="totalPages"
                      :total-visible="totalVisible"
                      @input="listMedia"
                    ></v-pagination>
                  </v-flex>
                </v-layout>
                <v-layout row wrap xs12 v-else class="no-media">
                  <v-flex xs12 text-xs-center>
                    <p class="text--disabled">
                      {{ $t("media_manager.no_image_selected") }}
                    </p>
                  </v-flex>
                </v-layout>
              </overlay-scrollbars>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout v-if="selected" row wrap>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex class="xs12 px-2 py-3 text-xs-right">
        <v-btn
          :loading="isLoading"
          round
          large
          :disabled="selected.length === 0"
          class="left white sw-accent text-none theme--light"
          @click="deleteSelected"
          >{{ $t("delete") }}
        </v-btn>
        <p class="d-inline-block pr-3 pl-3 error--text">
          {{
            $t("media_manager.no_of_image_selected", {
              has: selected.length,
              limit: maxFilesInsert,
            })
          }}
        </p>
        <v-btn
          v-if="imagePreview"
          :disabled="selected.length > maxFilesInsert || selected.length <= 0"
          :loading="isLoading"
          round
          large
          class="white sw-accent text-none theme--light"
          @click="openPreview(selected)"
        >
          {{ $t("media_manager.preview_show_button_text") }}
        </v-btn>
        <v-btn
          :disabled="selected.length === 0"
          :loading="isLoading"
          round
          large
          class="white sw-accent text-none theme--light"
          @click="deSelectAllMedia"
          >{{ $t("deselect") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          round
          large
          :disabled="selected.length > maxFilesInsert || selected.length <= 0"
          class="sw-accent-bg sw-on-accent theme--light text-none"
          @click="insertSelected"
          >{{ $t("insert") }}
        </v-btn>
      </v-flex>
    </v-layout>
    <DevicePreviewDialog
      ref="devicePreviewDialog"
      :background-size="imagePreviewBackgroundSize"
    />
    <DialogModal ref="dialogModal" />
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import DevicePreviewDialog from "@/components/Media/DevicePreview/DevicePreviewDialog.vue";
import DialogModal from "@/components/DialogModal.vue";

export default {
  props: {
    value: Array,
    mediaType: {
      type: String,
      default: null,
    },
    maxFilesInsert: {
      type: Number,
      default: 1,
    },
    imagePreview: {
      type: Boolean,
      default: false,
    },
    imagePreviewBackgroundSize: {
      type: String,
    },
  },
  components: {
    PerfectScrollbar,
    DevicePreviewDialog,
    DialogModal,
  },
  data: () => ({
    scrollbarOptions: {
      scrollbars: { autoHide: "leave" },
      overflowBehavior: {
        x: "scroll",
      },
      sizeAutoCapable: true,
    },
    isLoading: false,
    media: [],
    preview: {},
    selected: [],
    page: 1,
    perPage: 12,
    search: null,
    timeout: null,
    ready: false,
    editMode: false,
    loadMore: false,
    totalVisible: 5,
    totalPages: 0,
    lastCheckedId: -1,
  }),
  watch: {
    search() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.page = 0;
        this.searchMedia();
      }, 200);
    },
  },
  created() {
    this.listMedia(this.page);
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.selected = [...this.value];
      }
    });
  },
  methods: {
    openPreview(file) {
      if (
        !file &&
        !file[0] &&
        file[0].mime_type !== "image/png" &&
        file[0].mime_type !== "image/jpeg" &&
        file[0].mime_type !== "image/gif"
      ) {
        return;
      }

      this.$refs.devicePreviewDialog.openDialog(file[0].url);
    },
    typeCheck(mimeType, type) {
      switch (type) {
        case "pdf":
          return mimeType === "application/pdf";
        default:
          return (
            mimeType === "image/png" ||
            mimeType === "image/jpeg" ||
            mimeType === "image/gif"
          );
      }
    },
    getThumb(file) {
      return file && file.presets && file.presets.w500
        ? file.presets.w500
        : null;
    },
    resetScrollbarPosition() {
      if (this.$refs.perfectScrollbar && this.$refs.perfectScrollbar.$el) {
        this.$refs.perfectScrollbar.$el.scrollTop = 0;
      }
    },
    bytesToSize(bytes) {
      if (bytes) {
        let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Byte";
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return (
          Math.fround(bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i]
        );
      }
    },
    setLoadMore(data) {
      this.totalPages = data.pagination.total_pages;
      this.loadMore = data.pagination.total > this.media.length;
    },
    isSelected(media_id) {
      if (media_id && this.selected.length > 0) {
        let index = this.selected.findIndex((media) => media.id === media_id);
        if (index > -1) {
          return true;
        }
      }
    },
    insertSelected() {
      this.$emit("media-insert", this.selected ? this.selected : null);
    },
    async deleteSelected() {
      if (!this.selected || !this.selected.length) return;

      const isConfirmed = await this.$refs.dialogModal.open(
        this.$t("media_manager.confirmation"),
        this.$t("media_manager.media_deletion_warning"),
        this.$t("common.cancel"),
        this.$t("common.confirm"),
      );

      if (!isConfirmed) return;
      this.isLoading = true;
      const ids = this.selected.map((media) => media.id);
      this.$api.media.multipleDelete(ids).then(
        (res) => {
          this.media = this.media.filter((media) => !ids.includes(media.id));
          this.preview = {};
          this.selected = [];
          this.loadMore = true;
          this.$store.dispatch("addNotification", {
            message: this.$t("media_manager.media_file_deleted", {
              type: "files",
            }),
          });
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.errorMessageShow(err);
        },
      );
    },
    searchMedia() {
      this.resetScrollbarPosition();
      this.selected = [];
      this.lastCheckedIdx = -1;
      this.page = 1;

      const params = {
        media_type: this.mediaType,
        search: this.search,
        page: this.page,
        per_page: this.perPage,
      };

      this.$api.media
        .list(params)
        .then((response) => {
          this.media = response.data.data;
          this.page = this.page + 1;
          this.setLoadMore(response.data);
        })
        .catch((error) => {
          this.ready = true;
          this.errorMessageShow(error);
        });
    },
    listMedia(val) {
      const params = {
        media_type: this.mediaType,
        search: this.search,
        page: val,
        per_page: this.perPage,
      };

      this.$api.media
        .list(params)
        .then((response) => {
          this.media = response.data.data;

          if (this.media.length === 0) {
            this.$emit("show-upload");
          }
          this.page = val;
          this.ready = true;
          this.setLoadMore(response.data);
        })
        .catch(() => {
          this.ready = true;
        });
    },
    getNewMedia() {
      const params = {
        media_type: this.mediaType,
        search: this.search,
        page: this.page > 1 ? this.page - 1 : this.page,
        per_page: this.perPage,
      };

      this.$api.media
        .list(params)
        .then((response) => {
          for (const item of data.data) {
            const index = this.media.findIndex((media) => media.id === item.id);
            if (index === -1) {
              this.media.push(item);
            }
          }

          this.setLoadMore(response.data);
        })
        .catch(() => {
          this.ready = true;
        });
    },
    updateMedia(media) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const specs = [
          media.id,
          {
            name: media.name,
          },
        ];

        this.$api.media
          .update(...specs)
          .then((response) => {
            this.isLoading = true;

            const oldVal = this.media.find((el) => el.id === media.id);
            oldVal.name = media.name;
            this.$store.dispatch("addNotification", {
              message: this.$t("media_manager.media_file_updated", {
                type: "file",
              }),
            });
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.errorMessageShow(error);
          });
      }, 1500);
    },
    selectMedia(event, index, idFile) {
      const action =
        this.selected.findIndex((file) => file.id === idFile) === -1
          ? "select"
          : "deselect";
      if (event.shiftKey && this.lastCheckedIdx !== -1) {
        document.getSelection().removeAllRanges();
        let start = this.lastCheckedIdx;
        let end = index - 1;
        if (start > end) {
          start = index + 1;
          end = this.lastCheckedIdx;
        }

        for (let i = start; i <= end; i++) {
          const currentIdFile = this.media[i]["id"];
          this.markSelectDeselect(i, action, currentIdFile);
        }
      }

      this.markSelectDeselect(index, action, idFile);
      this.lastCheckedIdx = index;

      if (this.selected.length === 0) {
        //reset last checked if nothing selected
        this.lastCheckedIdx = -1;
      }
    },
    deSelectAllMedia() {
      this.selected = [];
    },
    markSelectDeselect(index, action, id) {
      const currentPos = this.selected.findIndex((media) => media.id === id);
      if (action === "select" && currentPos === -1) {
        this.selected.push(this.media[index]);
      } else if (action === "deselect" && currentPos !== -1) {
        this.selected.splice(currentPos, 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select-media {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10;
  cursor: pointer;
}

.file {
  position: relative;
  padding: 10px;
  border-radius: 0;
  height: 100%;
  width: 100%;
}

.file .card-container {
  padding: 0 !important;
}

.file-name {
  padding-left: 12px;
}

.file-name .v-text-field {
  padding-top: 4px;
}

.file-size {
  padding-left: 12px;
  padding-bottom: 14px;
  opacity: 0.6;
}

img {
  width: 100%;
  object-fit: contain;
  height: 160px;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.disable-events {
  pointer-events: none;
}

.img-item {
  height: auto;
  display: flex;
  align-items: center;
  padding: 12px 12px 0 12px;
}

.image-layout {
  height: 62vh;
  max-height: 600px;
  padding: 0;
}

.image-wrap {
  padding: 0 8px;
}

.card-container {
  background-color: #f5f5f5 !important;
  padding: 16px !important;
}

.error--text {
  color: $accent !important;
}

.accent--text {
  color: $accent !important;
}

.no-media {
  align-content: space-around;
  height: 100%;
}
</style>
