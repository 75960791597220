<template>
  <div>
    <v-dialog v-model="dialog" width="800" persistent>
      <v-card class="rounded">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="text-xs-center pt-0">
          <div class="mb-3 headline font-weight-medium">
            {{ $t("quillSourceTitle") }}
          </div>
          <v-textarea v-model="model" solo rows="14" no-resize></v-textarea>
          <v-btn
            round
            class="white sw-primary text-none"
            @click="closeDialog"
            >{{ $t("quillCancelButtonText") }}</v-btn
          >
          <v-btn
            round
            class="sw-secondary-bg sw-on-secondary text-none"
            @click="insertSource"
            >{{ $t("quillConfirmButtonText") }}</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "QuillSource",
  props: ["value"],
  data: () => ({
    model: "",
    dialog: false,
  }),
  watch: {
    dialog: {
      immediate: true,
      handler() {
        this.model = this.value;
      },
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    insertSource() {
      this.closeDialog();
      this.$emit("insert-quill-source", this.model);
    },
  },
};
</script>
