import Vue from "vue";
import {
    Vuetify,
    VApp,
    VNavigationDrawer,
    VFooter,
    VList,
    VBtn,
    VIcon,
    VGrid,
    VToolbar,
    transitions,
    VAvatar,
    VBadge,
    VMenu,
    VCard,
    VDivider,
    VProgressCircular,
    VSnackbar,
    VForm,
    VTextField,
    VCheckbox,
    VProgressLinear,
    VSwitch,
    VSubheader,
    VTooltip,
    VDialog,
    VAutocomplete,
    VDataTable,
    VSelect,
    VExpansionPanel,
    VBottomNav,
    VImg,
    VItemGroup,
    VPagination,
    VAlert,
    VStepper,
    VTabs,
    VTextarea,
    VTimeline,
    VRangeSlider,
    VSlider,
    VSpeedDial,
    VChip,
    VCombobox,
    VHover,
    VRadioGroup,
    VBtnToggle,
    VWindow,
} from "vuetify";
import "vuetify/src/stylus/app.styl";

Vue.use(Vuetify, {
    components: {
        VApp,
        VNavigationDrawer,
        VFooter,
        VList,
        VBtn,
        VIcon,
        VGrid,
        VToolbar,
        transitions,
        VAvatar,
        VBadge,
        VMenu,
        VCard,
        VDivider,
        VProgressCircular,
        VSnackbar,
        VForm,
        VTextField,
        VCheckbox,
        VProgressLinear,
        VSwitch,
        VSubheader,
        VTooltip,
        VDialog,
        VAutocomplete,
        VDataTable,
        VSelect,
        VExpansionPanel,
        VBottomNav,
        VImg,
        VItemGroup,
        VPagination,
        VAlert,
        VStepper,
        VTabs,
        VTextarea,
        VTimeline,
        VRangeSlider,
        VSlider,
        VSpeedDial,
        VChip,
        VCombobox,
        VHover,
        VRadioGroup,
        VBtnToggle,
        VWindow,
    },
    // options: {
    //   customProperties: true,
    // },
});
