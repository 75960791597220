const state = {
  vuexloading: [],
};

const getters = {
  vuexloading: (state) => state.vuexloading,
};

const mutations = {
  ADD_LOADER: (state, loader) => state.vuexloading.push(loader),
  REMOVE_LOADER: (state, loader) =>
    (state.vuexloading = state.vuexloading.filter((el) => el !== loader)),
};

const actions = {
  startLoading: ({ commit }, loader) => {
    commit("ADD_LOADER", loader);
  },
  endLoading: ({ commit }, loader) => {
    commit("REMOVE_LOADER", loader);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
