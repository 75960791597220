<template>
  <div class="video-tutorial" v-if="isTutorialsEnabled && tutorialVideoId">
    <v-btn
      round
      class="mx-0 sw-secondary-bg sw-on-secondary text-none"
      @click="playVideo"
    >
      {{ $t("tutorials.open") }}
    </v-btn>
    <v-dialog v-model="dialog" width="640">
      <v-card class="border-radius sw-primary-bg">
        <v-toolbar class="sw-primary-bg sw-on-primary">
          <v-toolbar-title class="pa-0 ma-0">
            <strong>{{ title }}</strong>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false" class="sw-on-primary">
            <v-icon small class="sw-on-primary">close</v-icon>
          </v-btn>
        </v-toolbar>
        <div
          ref="videoplayer"
          style="min-height: 360px"
          class="videoplayer-container"
        ></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import YouTubePlayer from "youtube-player";
import { tutorials } from "@/components/Tutorials/tutorials.json";

export default {
  props: {
    component: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
  },
  data: () => ({
    dialog: false,
    player: null,
  }),
  computed: {
    isTutorialsEnabled() {
      return process.env.VUE_APP_TUTORIALS_ENABLED === "true" ? true : false;
    },
    appLanguage() {
      return this.$store.getters.appLanguage;
    },
    tutorial() {
      return tutorials.find((el) => el.component === this.component);
    },
    tutorialVideoId() {
      return this.tutorial ? this.tutorial[this.appLanguage] : null;
    },
  },
  watch: {
    dialog(val) {
      if (!val && this.player) this.player.stopVideo();
    },
  },
  methods: {
    playVideo() {
      try {
        this.dialog = true;

        if (!this.$refs.videoplayer) return;

        if (this.player) {
          return this.player.cueVideoById(this.tutorialVideoId);
        }

        this.player = new YouTubePlayer(this.$refs.videoplayer, {
          width: "640",
          height: "360",
          // playerVars: { autoplay: 0 },
        });

        this.player.on("ready", (event) => {
          event.target.cueVideoById(this.tutorialVideoId);
        });
      } catch (error) {}
    },
  },
};
</script>

<style>
.video-tutorial .videoplayer-container {
  background: #000;
}
</style>
