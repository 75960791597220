<template>
  <div class="relative-container" :class="{ center }">
    <template v-if="message && render">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <font-awesome-icon
            icon="question"
            class="tooltip-icon"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <span v-html="message" />
      </v-tooltip>
    </template>
    <slot />
  </div>
</template>

<script>
import config from "./tooltip-config.js";
// import variables from '@/assets/styles/variables.scss';

export default {
  name: "Tooltip",
  props: {
    name: {
      type: String,
    },
    center: {
      type: Boolean,
    },
    params: {
      type: Object,
    },
    render: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    config: config,
  }),
  computed: {
    message() {
      let message = this.config[this.name] || "";
      Object.keys(this.params || {}).forEach((key) => {
        message = message.replaceAll(`:{${key}}`, this.params[key]);
      });
      return message;
    },
  },
};
</script>

<style lang="scss" scoped>
.relative-container {
  position: relative;
  width: fit-content;

  &.center {
    margin: 0 auto;
  }

  .tooltip-icon {
    color: $accent;
    position: absolute;
    top: 0px;
    right: -20px;
    border: 1px solid $accent;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    padding: 2px;
  }
}
</style>
